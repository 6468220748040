import { UIRouterModule } from "@uirouter/angular";
import { ProfilesComponent } from "./profiles.component";
import { ProfilesListComponent } from './profiles-list/profiles-list.component';
import { ProfilesDetailsComponent } from './profiles-details/profiles-details.component'

const routesConfig = {
  states: [
    { name: 'profiles', redirectTo: 'profiles.list', component: ProfilesComponent, data: { requiresAuth: true } },
    { name: 'profiles.list', url: '/profiles?name&address&user&status&promoted&page&sort&servicePlus', component: ProfilesListComponent, data: { requiresAuth: true } },
    { name: 'profiles.listVerified', url: '/profiles/verified', component: ProfilesListComponent, data: { query: { status: "verified" }, requiresAuth: true } },
    { name: 'profiles.listPending', url: '/profiles/pending', component: ProfilesListComponent, data: { query: { status: "pending" }, requiresAuth: true } },
    { name: 'profiles.listRejected', url: '/profiles/rejected', component: ProfilesListComponent, data: { query: { status: "rejected" }, requiresAuth: true } },
    { name: 'profiles.listPromoted', url: '/profiles/promoted', component: ProfilesListComponent, data: { query: { status: "promoted" }, requiresAuth: true } },
    { name: 'profiles.servicePlus', url: '/profiles/servicePlus', component:  ProfilesListComponent, data: {query: {status: "is_service_plus"},requiresAuth: true}},
    { name: 'profiles.listPage', url: '/profiles/page/:pageNumber', component: ProfilesListComponent, data: { requiresAuth: true } },
    { name: 'profiles.listPageVerified', url: '/profiles/verified/page/:pageNumber', component: ProfilesListComponent, data: { query: { status: "verified" }, requiresAuth: true } },
    { name: 'profiles.listPagePending', url: '/profiles/pending/page/:pageNumber', component: ProfilesListComponent, data: { query: { status: "pending" }, requiresAuth: true } },
    { name: 'profiles.listPageRejected', url: '/profiles/rejected/page/:pageNumber', component: ProfilesListComponent, data: { query: { status: "rejected" }, requiresAuth: true } },
    { name: 'profiles.details', url: '/profiles/:id', component: ProfilesDetailsComponent, data: { requiresAuth: true } },
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig);