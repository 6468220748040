import { Component, OnInit, Input } from '@angular/core';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'promotional-codes',
  templateUrl: './promotional-codes.component.html',
  styleUrls: ['./promotional-codes.component.scss']
})
export class PromotionalCodesComponent implements OnInit {

  @Input('user') user: any;
  user_codes: any;

  constructor(private _tokenService: Angular2TokenService) { }

  ngOnInit() {
    this.getUserCodes(this.user.id)
    .subscribe(res => {
      this.user_codes = res.data;
    })
  }

  getUserCodes(user_id){
    let url = `${environment.apiEndpoint}/users/${user_id}/promotional_codes`;
    return this._tokenService.get(url)
            .map( response => response.json() )
  }
}
