import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { routerModule } from "./agregations.routing";
import { AgmCoreModule } from '@agm/core';

import { UtilitiesModule } from "../utilities/utilities.module";
import { AgregationsService } from './../api/agregations.service';
import { AgregationsComponent } from './agregations.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routerModule,
    AgmCoreModule,
    UtilitiesModule,
  ],
  declarations: [AgregationsComponent],
  providers:[AgregationsService]
})
export class AgregationsModule { }
