import { Injectable } from '@angular/core';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../environments/environment";
import { Http, Response, RequestOptions, Headers, RequestMethod } from '@angular/http';
import { Observable, Subject } from 'rxjs';

export interface Booking {
  id?: number,
  status?: string,
  date: any,
  time_from: string,
  time_to: string,
  service?: object
}

export interface CallendarBooking {
  date: string;
  time_from: string;
  time_to: string;
  service_variant_id: number;
  first_name: string;
  last_name: string;
  email: string;
  user_id: number;
  notes: string;
  phone: string;
}

@Injectable()
export class BookingsService {

  constructor(private http:Http,
              private _tokenService: Angular2TokenService) { }

  // getBookings(page) {
  //   let url = `${environment.apiEndpoint}/bookings?page=${page}`;
  //   return this._tokenService.get(url)
  //           .map( response => response.json() )
  // }

  getBookings(page, query, sort) {
    let q = "";
    let s = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiEndpoint}/bookings?page=${page}${q}`;
    return this._tokenService.get(url)
            .map( response => response.json() )
  }

  getServiceBookings(serviceId: number, query: any) {
    let q = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    let url = `${environment.apiEndpoint}/services/${serviceId}/bookings?${q}`;
    return this._tokenService.get(url)
      .map( response => response.json())
  }

  getBooking(serviceId, bookingId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/bookings/${bookingId}`;
    return this._tokenService.get(url)
            .map( response => response.json() )
  }

  cancelBooking(serviceId, bookingId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/bookings/${bookingId}`;
    return this._tokenService.delete(url)
            .map( response => response.json() )
  }

  updateBooking(serviceId, booking) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/bookings/${booking.id}`;
    return this._tokenService.put(url, booking)
            .map( response => response.json() )
  }

  addBooking(serviceId, booking: CallendarBooking) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/bookings`;
    return this._tokenService.post(url,booking)
      .map( response => response.json())
  }

}
