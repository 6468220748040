import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { ServicesService } from 'app/api/services.service';
import { Observable, of, Subject, merge } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap, filter } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'callendar-bookings-search',
  templateUrl: './callendar-bookings-search.component.html',
  styleUrls: ['./callendar-bookings-search.component.scss']
})
export class CallendarBookingsSearchComponent {

  @ViewChild('searchInput')
  public searchInput: NgbTypeahead;

  @Output()
  public onFind:EventEmitter<any> = new EventEmitter<any>();

  public model: string | any;
  public searching: boolean = false;
  public searchFailed: boolean = false;
  public focus$:Subject<string> = new Subject<string>();
  public click$:Subject<string> = new Subject<string>();


  constructor(private servicesService:ServicesService) { }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.searchInput.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(query => {
        if (query.length) {
          const q = {
            name_cont: query,
            is_service_plus_eq: true,
          }
          return this.servicesService.findService(q).pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }))
        } else {
          return of([])
        }
      }),
      tap(() => this.searching = false)
    )
  }

  formatter = (x: {name: string}) => x.name;

  onSearchInputChange() {
    if (this.model.id) {
      this.onFind.emit(this.model)
    }
  }
}
