import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Angular2TokenService } from 'angular2-token';
import { environment } from 'environments/environment';

export interface IBlog {
  id?:number;
  name: string;
  title: string;
  image: string,
  short: string,
  content: string;
  published?: boolean,
  blog_postable_id?: number,
  blog_postable_type?: string
  user?: {
    [prop: string]: any
  }
}

@Injectable()
export class BlogService {

  constructor(private tokenService: Angular2TokenService) { }

  getAllPost() {
    let url = `${environment.apiEndpoint}/blog_posts`;
    return this.tokenService.get(url)
      .map(respnse => respnse.json());
  }

  getPost(id: number): Observable<any> {
    let url = `${environment.apiEndpoint}/blog_posts/${id}`;
    return this.tokenService.get(url)
      .map(respnse => respnse.json());
  }

  save(blog: IBlog) {
    let url = `${environment.apiEndpoint}/blog_posts`;
    return this.tokenService.post(url,{blog_post: blog})
      .map(response => response.json());
  }

  removePost(id: number) {
    let url = `${environment.apiEndpoint}/blog_posts/${id}`;
    return this.tokenService.delete(url)
      .map(respnse => respnse.json())
  }

  updatePost(id: number, blog: IBlog) {
    let url = `${environment.apiEndpoint}/blog_posts/${id}`;
    return this.tokenService.put(url, blog)
      .map(response => response.json());
  }

  publishPost(id: number, published: boolean) {
    let url = `${environment.apiEndpoint}/blog_posts/${id}/publish`
    return this.tokenService.put(url,{ published })
      .map(response => response.json());
  }
}
