import { UIRouterModule } from "@uirouter/angular";
import { AuthComponent } from "./auth.component";
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routesConfig = {
  states: [
    { name: 'auth', redirectTo: 'auth.login', component:  AuthComponent, data:{hideNav: true}},
    { name: 'auth.login', url:'/login', component:  AuthLoginComponent},
    { name: 'resetPassword', url: '/password/reset/?reset_password_token', component:  ResetPasswordComponent}
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
