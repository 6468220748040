import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { Angular2TokenService } from 'angular2-token';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  data: object;
  error

  constructor(private stateService: StateService,
              private _tokenService: Angular2TokenService) { }

  ngOnInit() {
    this.data = {
      resetPasswordToken: this.stateService.params.reset_password_token,
      passwordCurrent: null
    }
    console.log(this.stateService)
    console.log(this.data)
  }

  resetPassword(form, data) {
    console.log(data);
    if (form.invalid) {
        return
    }

    this._tokenService.updatePassword(data)
      .subscribe(res => {
        console.log(res)
      },
      error =>{
        this.error = JSON.parse(error._body);
        console.log(this.error);
      })
  }

}
