import { Injectable } from '@angular/core';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../environments/environment";

@Injectable()
export class ServiceUnavailabilityService {

  constructor(private _tokenService: Angular2TokenService) { }

  getBlocks(serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/unavailabilities`;
    return this._tokenService.get(url)
            .map(res => res.json())
  }

  newBlock(serviceId, block) {
    console.log(block)
    let url = `${environment.apiEndpoint}/services/${serviceId}/unavailabilities`;
    return this._tokenService.post(url, block)
            .map(res => res.json())
  }

  editBlock(serviceId, block) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/unavailabilities/${block.id}`
    return this._tokenService.put(url, block)
            .map(res => res.json())
  }

  removeBlock(serviceId, blockId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/unavailabilities/${blockId}`
    return this._tokenService.delete(url, blockId)
            .map(res => res.json())
  }

}
