import { Component, OnInit } from '@angular/core';
import { BlogService, IBlog } from './../../api/blog.service';
import { ToastrService } from 'ngx-toastr';
import { StateService } from "@uirouter/angular";

@Component({
  selector: 'blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {

  posts:Array<IBlog>;
  pagination;

  constructor(private blogService: BlogService,
              private toastr: ToastrService,
              private stateService: StateService) { }

  ngOnInit() {
    let currentPage = this.stateService.params.pageNumber || 1;
    this.blogService.getAllPost().subscribe(
      res => {
        this.posts = res['data'];
        let paginationObject = {
          currentPage: currentPage,
          size: res.size,
          perPage: res.per_page
        }

        this.pagination = paginationObject;
      },
      err => console.log('err')
    );
  }

  changeStatus(params) {
    if (params.remove) {
      this.removePost(params.id);
      return
    }
    if(params.publish == true || params.publish == false) {
      this.publishPost(params.id, params.publish);
    }
  }

  private removePost(id: number) {
    this.blogService.removePost(id).subscribe(
      res => {
        this.toastr.success('post deleted successfully');
        const postIndex = this.posts.map((post) => post.id).indexOf(id);
        this.posts.splice(postIndex,1);
      },
      err => console.log(err)
    )
  }

  private publishPost(id: number, publish: boolean) {
    this.blogService.publishPost(id, publish).subscribe(
      res => {
        this.toastr.success(`Post successfully ${publish ? 'published' : 'unpublished'}`);
        const postIndex = this.posts.map((post) => post.id).indexOf(id);
        this.posts[postIndex].published = publish;
      },
      err => console.log(err)
    );
  }

  pageChange(page) {
    if (page != this.stateService.params.pageNumber) {
      this.stateService.go('blog.listPage', {pageNumber: page})
    }
  }
}
