import { Component, OnInit } from '@angular/core';
import { ReviewsService } from './../../api/reviews.service';
import { UIRouter, StateService } from "@uirouter/angular";

@Component({
  selector: 'review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss']
})
export class ReviewListComponent implements OnInit {
  reviews: any = []
  query: any = {};
  queryParams: any = [
    {k: "status", v: "status_or_response_status_eq"}
  ];
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  sort: any;
  tableSort: any = [];
  constructor (
    private stateService:StateService,
    private route:UIRouter,
    private reviewsService: ReviewsService,
  ) {
    this.setQueryParams();
    this.setListParams();
    this.getReviews();
  }

  ngOnInit() {
  }
  
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.changeLocation();
  }

  onSort(event) {
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.sort = prop + " " + s.dir;
    this.page.pageNumber = 0;
    this.changeLocation();
  }

  getReviews() {
    this.reviewsService.get(this.page.pageNumber + 1, this.query, this.sort).subscribe(
      res => {
        this.page.totalElements = res.size;
        this.page.size = res.per_page;
        this.reviews = res.data
      }
    )
  }
  
  onQueryChange(event) {
    this.query = {...this.query, ...event};
    this.changeLocation();
  }
  
  changeLocation() {
    let params = this.prepareQueryParams();
    this.stateService.transitionTo('reviews.list', params);
  }
  
  setQueryParams() {
    for (let param of this.queryParams) {
      this.query[param.v] = this.route.globals.params[param.k] || '';
    }
  }
  
  setListParams() {
    let params = this.route.globals.params;
    this.page.pageNumber = params.page ? parseInt(params.page) : 0;
    if (params.sort) {
      this.sort = decodeURIComponent(params.sort);
      this.tableSort = [{prop: this.sort.split(' ')[0], dir: this.sort.split(' ')[1]}];
    } else {
      this.sort = '';
      this.tableSort = [];
    }
  }
  
  prepareQueryParams() {
    let q = {};
    for (let param of this.queryParams) {
      q[param.k] = this.query[param.v];
    }
    q["page"] = this.page.pageNumber;
    q["sort"] = this.sort;
    return q;
  }
  
  show(id) {
    this.stateService.transitionTo('reviews.detail', {id: id});
  }

}
