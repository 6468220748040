import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCardComponent } from './user-card/user-card.component';
import { LocationCardComponent } from './location-card/location-card.component';
import { GalleryCardComponent } from './gallery-card/gallery-card.component';
import { UIRouterModule } from "@uirouter/angular";
import { LeafletModule } from '@asymmetrik/angular2-leaflet';
import { AgmCoreModule } from '@agm/core';
import { ServiceCardComponent } from './service-card/service-card.component';
import { CheckModalComponent } from './check-modal/check-modal.component';
import { ForgotModalComponent } from './forgot-modal/forgot-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RejectServiceModalComponent } from './reject-service-modal/reject-service-modal.component';
import { AddressSearchComponent } from './address-search/address-search.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    LeafletModule,
    AgmCoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    UIRouterModule.forChild()
  ],
  exports: [
    UserCardComponent,
    LocationCardComponent,
    GalleryCardComponent,
    ServiceCardComponent,
    AddressSearchComponent,
    UserSearchComponent,
  ],
  entryComponents: [
    CheckModalComponent,
    ForgotModalComponent,
    RejectServiceModalComponent
  ],
  declarations: [UserCardComponent, LocationCardComponent, GalleryCardComponent, ServiceCardComponent, CheckModalComponent, ForgotModalComponent, RejectServiceModalComponent, AddressSearchComponent, UserSearchComponent]
})
export class UtilitiesModule { }
