import { Component, OnInit } from '@angular/core';
import { ServiceAvailabilityService } from "../../api/service-availability.service";
import { StateService } from "@uirouter/angular";
import * as Moment from "moment";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'service-prices-edit',
  templateUrl: './service-prices-edit.component.html',
  styleUrls: ['./service-prices-edit.component.scss']
})
export class ServicePricesEditComponent implements OnInit {

  availability;
  serviceId;

  constructor(private availabilityService: ServiceAvailabilityService,
              private stateService: StateService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.serviceId = this.stateService.params.id;

    this.availabilityService.getAvailability(this.serviceId)
      .subscribe( res => {
        console.log(res)
        this.availability = res;

        for (let i = 0; i < this.availability.hours.length; i++) {
          if (this.availability.hours[i].available) {
            this.createHours(this.availability.hours[i], this.availability.interval);
          }
        }

      })

  }

  createHours(day, interval) {
    let times = [];
    day.currentFrom = "From";
    day.currentTo = "To";
    for (let i = 0; i < day.open.length; i++) {
      let startDay = Moment(day.open[i].from, 'HH:mm');
      let endDay = Moment(day.open[i].to, 'HH:mm');
      let t = endDay.diff(startDay, 'm') / interval;

      while (t--) {
        let hourObject = {
          from: startDay.format('HH:mm'),
          to: ""
        }
        startDay.add(interval, 'minute');
        hourObject.to = startDay.format('HH:mm')
        times.push(hourObject)
      }

      day.intervals = times;
      console.log(this.availability)

    }
  }

  editPrice(day, from, to, newCost){

    let newPrices = [];

    for (let i = 0; i < day.prices.length; i++) {
      let start = Moment(day.prices[i].from, 'HH:mm');
      let end = Moment(day.prices[i].to, 'HH:mm');
      let oldCost = day.prices[i].cost;

      let newStart = Moment(from, 'HH:mm');
      let newEnd = Moment(to, 'HH:mm');

      if (newStart.isAfter(newEnd)) {
        newStart = Moment(to, 'HH:mm');
        newEnd = Moment(from, 'HH:mm');
      }

      let newObj = {
        from: newStart.format('HH:mm'),
        to: newEnd.format('HH:mm'),
        cost: parseInt(newCost)
      }

      if (newStart.isSameOrAfter(end)) {
        newPrices.push({
          from: start.format('HH:mm'),
          to: end.format('HH:mm'),
          cost: oldCost,
        })
        continue;
      }

      if (newStart.isSame(start)) {
        newPrices.push(newObj)
      } else if (newStart.isBetween(start, end, null, '()')) {
        newPrices.push({
          from: start.format('HH:mm'),
          to: newStart.format('HH:mm'),
          cost: oldCost
        }, newObj)
      }

      if (newEnd.isSameOrAfter(end)) {
        if (newEnd.isSameOrBefore(start)) {
          newPrices.push({
            from: start.format('HH:mm'),
            to: end.format('HH:mm'),
            cost: oldCost,
          })
        }
        continue;
      } else if (newEnd.isBetween(start, end, null, '()')) {
        newPrices.push({
          from: newEnd.format('HH:mm'),
          to: end.format('HH:mm'),
          cost: oldCost,
        })
      } else if (newEnd.isSameOrBefore(start)) {
        newPrices.push({
          from: start.format('HH:mm'),
          to: end.format('HH:mm'),
          cost: oldCost,
        })
      }
    }

    let closedHours = []

    for (let i = 0; i < day.open.length - 1; i++) {
      closedHours.push({
        from: Moment(day.open[i].to, 'HH:mm'),
        to: Moment(day.open[i + 1].from, 'HH:mm')
      })
    }

    for (let i = 0; i < newPrices.length; i++) {
      for (let j = 0; j < closedHours.length; j++) {
        let fromMoment = Moment(newPrices[i].from, "HH:mm");
        let toMoment = Moment(newPrices[i].to, "HH:mm");

        if (closedHours[j].to.isBetween(fromMoment, toMoment, null, "()")) {
          newPrices.splice(i, 1, {
            from: newPrices[i].from,
            to: closedHours[j].from.format('HH:mm'),
            cost: newPrices[i].cost
          }, {
            from: closedHours[j].to.format('HH:mm'),
            to: newPrices[i].to,
            cost: newPrices[i].cost
          })
        }
      }
    }


    day.prices = JSON.parse(JSON.stringify(newPrices));
    day.addCost = false;
    from = "From";
    to = "To";
    newCost = "";

  }

  save(ava) {
    this.availabilityService.updateAvailability(this.serviceId, ava)
      .subscribe( res => {
        this.toastr.success('Service prices saved.', 'Success!')
        console.log(res);
        this.stateService.go('services.details', {id: this.serviceId})
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
  }

  cancel() {
    this.stateService.go('services.details', {id: this.serviceId})
  }

}
