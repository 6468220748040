import { UIRouterModule } from "@uirouter/angular";
import { BlogComponent } from './blog.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogFormComponent } from './blog-form/blog-form.component';
import { BlogPreviewComponent } from './blog-preview/blog-preview.component';

const routesConfig = {
  states: [
    { name: 'blog', redirectTo: 'blog.list', component: BlogComponent, data: {requiresAuth: true}},
    { name: 'blog.list', url: '/blog', component:  BlogListComponent, data: {requiresAuth: true}},
    { name: 'blog.listPage', url: '/blog/page/:pageNumber', component:  BlogListComponent, data: {requiresAuth: true}},
    { name: 'blog.new', url: '/blog/new', component: BlogFormComponent , data: {requiresAuth: true}},
    { name: 'blog.edit', url: '/blog/post/:postId/edit', component:  BlogFormComponent, data: {requiresAuth: true}},
    { name: 'blog.preview', url: '/blog/post/:postId/preview', component:  BlogPreviewComponent, data: {requiresAuth: true}},
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
