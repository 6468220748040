import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gallery-card',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss']
})
export class GalleryCardComponent implements OnInit {

  @Input()
  images;

  @Output()
  imageRemoved = new EventEmitter();

  @Output()
  imageAsDefault = new EventEmitter();

  @Output()
  newImage = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  setAsDefault(image){
    this.imageAsDefault.emit(image);
  }

  removeImage(image) {
    this.imageRemoved.emit(image);
  }

  imageAdded(event) {
    this.newImage.emit(event);
  }

}
