import { Component, OnInit, OnDestroy } from '@angular/core';
import { DisciplinesService, Discipline } from "../api/disciplines.service";
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../services/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../services/service-changes-validation/service-changes-validation.service';

@Component({
  selector: 'disciplines',
  templateUrl: './disciplines.component.html',
  styleUrls: ['./disciplines.component.scss']
})
export class DisciplinesComponent implements OnInit {

  disciplines: Array<Discipline>;
  selectedDisc: Discipline;

  constructor(private disciplinesService: DisciplinesService,
                private checkChangesService: ServiceChangesCheckService,
                private changesValidationService: ServiceChangesValidationService,
                private transitionService: TransitionService) {
                } 

  ngOnInit() {
    this.disciplinesService.getDisciplines()
      .subscribe( res => {
        console.log(res)
        this.disciplines = res;
        this.selectedDisc = Object.assign({},this.disciplines[0]);
        this.checkChangesService.assignObject(this.selectedDisc);
      })
    this.changesValidationService.resetResolved();
  }

  selectDiscipline(disciplines) {
    this.selectedDisc = Object.assign({},disciplines)
    this.checkChangesService.assignObject(this.selectedDisc);
  }

  save(disc) {
    if (disc.id) {
      this.disciplinesService.saveDiscipline(disc)
        .subscribe(res => {
          console.log(this.disciplines.indexOf(disc))
          for (let i = 0; i < this.disciplines.length; i++) {
            if (this.disciplines[i].id == res.id) {
                this.disciplines.splice(i, 1, res);
            }
          }
        })
    } else {
      this.disciplinesService.newDiscipline(disc)
        .subscribe(res => {
          console.log('new', res);
          this.disciplines.push(res);
        })
    }
  }

}
