import { Component, OnInit } from '@angular/core';
import { Angular2TokenService } from 'angular2-token';

@Component({
  selector: 'profile-safety',
  templateUrl: './profile-safety.component.html',
  styleUrls: ['./profile-safety.component.scss']
})
export class ProfileSafetyComponent implements OnInit {

  data: object = {};

  constructor(private _tokenService: Angular2TokenService,) { }

  ngOnInit() {
  }

  save(formInvalid, data) {
    this._tokenService.updatePassword(data)
      .subscribe(res => {
      }, err => {
        console.log(err)
      })
  }

}
