import { Component, OnInit, Input } from '@angular/core';
import { ServiceAvailabilityService } from "../../api/service-availability.service";

@Component({
  selector: 'service-prices',
  templateUrl: './service-prices.component.html',
  styleUrls: ['./service-prices.component.scss']
})
export class ServicePricesComponent implements OnInit {

  @Input()
  serviceId;

  availability;

  constructor(private availabilityService: ServiceAvailabilityService) { }

  ngOnInit() {
    this.availabilityService.getAvailability(this.serviceId)
      .subscribe( res => {
        console.log(res)
        this.availability = res;
      })
  }

}
