import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'promotional-codes-form',
  templateUrl: './promotional-codes-form.component.html',
  styleUrls: ['./promotional-codes-form.component.scss']
})
export class PromotionalCodesFormComponent implements OnInit {

  id: any;
  code: any = {
    identifier: "",
    description: "",
    discount: "",
    discount_type: ""
  }
  discountTypes = ["fixed", "percentage"]

  constructor(private stateService: StateService,
              private _tokenService: Angular2TokenService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.id = this.stateService.params.id
    if (this.id != 'new') {
      let url = `${environment.apiEndpoint}/users/${this.stateService.params.user_id}/promotional_codes/${this.stateService.params.id}`;
      this._tokenService.get(url)
              .map( response => response.json() )
              .subscribe(
                res => {
                  this.code = res
                }
              )
    }
  }

  save() {
    if (this.id == 'new') {
      let url = `${environment.apiEndpoint}/users/${this.stateService.params.user_id}/promotional_codes`;
      return this._tokenService.post(url, {promotional_code: this.code})
              .map( response => response.json() )
              .subscribe(
                res => {
                  this.toastr.success("Dodano kod promocyjny", "SUKCES!");
                  this.stateService.go('users.details', {id: this.stateService.params.user_id}, { location: false });
                },
                err => {
                  this.toastr.error("Błąd", "BŁĄD!");
                }
              )
    } else {
      let url = `${environment.apiEndpoint}/users/${this.stateService.params.user_id}/promotional_codes/${this.stateService.params.id}`;
      return this._tokenService.put(url, {promotional_code: this.code})
              .map( response => response.json() )
              .subscribe(
                res => {
                  this.toastr.success("Zaktualizowano kod promocyjny", "SUKCES!");
                  this.stateService.go('users.details', {id: this.stateService.params.user_id}, { location: false });
                },
                err => {
                  this.toastr.error("Błąd", "BŁĄD!");
                }
              )
    }
  }

}
