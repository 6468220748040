import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { City } from "../../api/cities.service";

@Component({
  selector: 'cities-list',
  templateUrl: './cities-list.component.html',
  styleUrls: ['./cities-list.component.scss']
})
export class CitiesListComponent implements OnInit {

  @Input()
  cities: Array<City>;

  @Output()
  citySelected = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  selectCity(city) {
    this.citySelected.emit(city);
  }

}
