import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { routerModule } from "./reviews.routing";
import { ReviewsComponent } from './reviews.component';
import { ReviewsService } from './../api/reviews.service';
import { ReviewListComponent } from './review-list/review-list.component';
import { ReviewDetailComponent } from './review-detail/review-detail.component';
import { ReviewFiltersComponent } from './review-filters/review-filters.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxDatatableModule,
    routerModule,
  ],
  declarations: [
    ReviewsComponent,
    ReviewListComponent,
    ReviewDetailComponent,
    ReviewFiltersComponent,
  ],
  providers: [
    ReviewsService,
  ]
})
export class ReviewsModule { }
