import { UIRouterModule } from "@uirouter/angular";
import { ReviewsComponent } from "./reviews.component";
import { ReviewListComponent } from "./review-list/review-list.component";
import { ReviewDetailComponent } from "./review-detail/review-detail.component";

const routesConfig = {
  states: [
    { name: 'reviews', redirectTo: 'reviews.list', component:  ReviewsComponent, data: {requiresAuth: true}},
    { name: 'reviews.list', url: '/reviews?name&address&user&status&promoted&page&sort', component:  ReviewListComponent, data: {requiresAuth: true}},
    { name: 'reviews.detail', url: '/review/:id', component:  ReviewDetailComponent, data: {requiresAuth: true}},
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
