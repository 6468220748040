import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { Angular2TokenService } from 'angular2-token';

export interface Discipline {
  id?: number,
  name: string
}

@Injectable()
export class DisciplinesService {

  constructor(private _tokenService: Angular2TokenService) { }

  getDisciplines() {
    let url = `${environment.apiEndpoint}/disciplines`;
    return this._tokenService.get(url)
            .map( response => response.json() )
  }

  saveDiscipline(disc) {
    let url = `${environment.apiEndpoint}/disciplines/${disc.id}`;
    return this._tokenService.put(url, disc)
            .map( response => response.json() )
  }

  newDiscipline(disc) {
    let url = `${environment.apiEndpoint}/disciplines`;
    return this._tokenService.post(url, disc)
            .map( response => response.json() )
  }

}
