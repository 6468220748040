import { Component, OnInit, Input } from '@angular/core';
import { ServiceHoursModalComponent } from "../service-hours-modal/service-hours-modal.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceAvailabilityService } from "../../api/service-availability.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'service-availability',
  templateUrl: './service-availability.component.html',
  styleUrls: ['./service-availability.component.scss']
})
export class ServiceAvailabilityComponent implements OnInit {

  @Input()
  serviceId;

  intervalsMin = [60,45,30];

  availability: object;

  hours: Array<object>

  constructor(private modalService: NgbModal,
              private availabilityService: ServiceAvailabilityService,
              private toastr: ToastrService) { }

  ngOnInit() {

    this.availabilityService.getAvailability(this.serviceId)
      .subscribe( res => {
        console.log(res)
        this.availability = res;
      })

  }

  intervalChange(value, ava) {
    if (confirm('Changing interval will resault in reseting your object availability hours, are you sure?')) {
      ava.interval = value;
      this.resetHours(this.availability)
    }
  }

  openHoursModal(day, interval) {
    const modalRef = this.modalService.open(ServiceHoursModalComponent);
    modalRef.componentInstance.day = day;
    modalRef.componentInstance.interval = interval;
  }

  resetHours(ava) {
    for (let i = 0; i < ava.hours.length; i++) {
        ava.hours[i].open = [{from: "09:00", to: "21:00"}];
    }
  }

  save(ava) {
    ava.days = 0;

    for (let i = 0; i < ava.hours.length; i++) {
      if (ava.hours[i].available && ava.hours[i].open.length > 0) {
        ava.days += 2**(7 - ava.hours[i].id);
      } else {
        ava.hours[i].available = false;
      }
      ava.hours[i].prices = [];
      for (let j = 0; j < ava.hours[i].open.length; j++) {
        // TODO: change 50 to standard price for this service or change this function for better one or on backend
        ava.hours[i].prices.push({
          from: ava.hours[i].open[j].from,
          to: ava.hours[i].open[j].to,
          cost: 50
        })
      }
    }
    this.availabilityService.updateAvailability(this.serviceId, ava)
      .subscribe( res => {
        this.toastr.success('Service availability saved.', 'Success!')
        console.log(res);
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
  }

  cancel() {
    this.availabilityService.getAvailability(this.serviceId)
      .subscribe( res => {
        console.log(res)
        this.availability = res;
      })
  }

}
