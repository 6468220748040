import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotModalComponent } from '../forgot-modal/forgot-modal.component'

@Component({
  selector: 'user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent {

  @Input()
  user: any;

  @Input()
  displayOnly = false;

  constructor(public modalService: NgbModal) { }

  resetPassword(user: any) {
    const modalRef = this.modalService.open(ForgotModalComponent, {windowClass: 'align-modal-center forgot-modal'});
    modalRef.componentInstance.email.next(user.email);
    modalRef.componentInstance.email.complete();
  }

}
