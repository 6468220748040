import { Component, OnInit, Input } from '@angular/core';
import { ServicesService } from '../../api/services.service';
import { UIRouter } from "@uirouter/angular";
import * as
  L from 'leaflet';

@Component({
  selector: 'location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss']
})

export class LocationCardComponent implements OnInit {

  @Input()
  location;
  @Input()
  service;

  mapOptions = {};
  addresses = [];
  response;

  constructor(private servicesService: ServicesService,
    private route: UIRouter) { }

  ngOnInit() {
    for (let i = 0; i < this.service.addresses.length; i++) {
      this.addresses.push(this.service.addresses[i])
    }
    for (let i = 0; i < this.service.services.length; i++) {
      for (let j = 0; j < this.service.services[j].length; j++) {
        this.addresses.push(this.service.services[i].addresses)
      }
    }
    this.initMapOptions()
  }

  initMapOptions() {
    this.mapOptions = {
      layers: [
        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
      ],
      zoom: 8,
      center: L.latLng({ lat: parseFloat(this.addresses[0].lat), lng: parseFloat(this.addresses[0].lng) })
    };
  }

  onMapReady(map: L.Map) {
    let icon = L.icon({ iconUrl: 'assets/marker-icon.png', shadowUrl: 'assets/marker-shadow.png', iconAnchor: [13, 41], })
    for (let address of this.addresses) {
      L.marker([parseFloat(address.lat), parseFloat(address.lng)], { icon: icon }).addTo(map);
    }
  }
}
