import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from "../../api/users.service";
import { UIRouter, StateService, TransitionService, Transition } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { ServiceChangesCheckService } from '../../services/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../services/service-changes-validation/service-changes-validation.service';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnDestroy {

  constructor(
    private usersService:UsersService,
    private route:UIRouter,
    private stateService:StateService,
    private toastr: ToastrService,
    private checkChangesService: ServiceChangesCheckService,
    private changesValidationService: ServiceChangesValidationService,
    transitionService: TransitionService,
    ) {
      this.transitionHook = transitionService.onBefore({from: 'users.**'}, (trans: Transition)=>{
          return changesValidationService.validate(this.user)
      });
    }

  transitionHook: any;
  file: File;
  user = {
    id: null,
    description: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    picture: "",
    type: "",
    is_company_admin: false,
  };
  types = ["Client", "Host", "Admin"]

  ngOnInit() {
    if (this.route.globals.params.id) {
      let id = this.route.globals.params.id;
      this.usersService.getUser(id)
      .subscribe(user => {
        this.user = user;
        this.checkChangesService.assignObject(this.user);
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
    } else {
      this.checkChangesService.assignObject(this.user);
    }
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  save(formInvalid, user) {
    this.changesValidationService.resolved = true;

    if (formInvalid) {
      this.toastr.warning('Fill all required data.', null);
      return
    }

    let formData:FormData = new FormData();

    for (let key in user) {
      formData.append(key, user[key])
    }

    if (this.file) {
      formData.append('avatar', this.file, this.file.name);
      this.toastr.info('Uploading data.', null);
    }

    if (this.route.globals.params.id) {
      this.usersService.editUser(formData, user.id)
        .subscribe( (user) => {
          this.toastr.success('User info edited.', 'Success!');
          this.file = null;
        }, (err) => {
          console.error(err)
          this.toastr.error(err.statusText, err.status);
        })
    } else {
      this.usersService.newUser(formData)
        .subscribe( (user) => {
          this.toastr.success('User has been created.', 'Success!')
          this.stateService.go('users.details', {id: user.id})
        }, (err) => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
        })
    }

  }

  cancel() {
    if (this.route.globals.params.id) {
      this.stateService.go('users.details', {id: this.route.globals.params.id})
    } else {
      this.stateService.go('users.list')
    }
  }

  fileChange(event, user) {
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      this.file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        user.picture = reader.result;
      }
    }
  }

}
