import { Component, OnInit } from '@angular/core';
import { CitiesService, City } from "../api/cities.service";


@Component({
  selector: 'cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {

  cities: Array<City>;
  selectedCity: City;

  constructor(private citiesService: CitiesService) { }

  ngOnInit() {
    this.citiesService.getCities()
      .subscribe( res => {
        console.log(res)
        this.cities = res;
      })
  }

  selectCity(city) {
    console.log(city)
    this.selectedCity = Object.assign({},city)
  }

  save(city) {
    if (city.id) {
      this.citiesService.saveCity(city)
        .subscribe(res => {
          console.log(this.cities.indexOf(city))
          for (let i = 0; i < this.cities.length; i++) {
            if (this.cities[i].id == res.id) {
                this.cities.splice(i, 1, res);
            }
          }
        })
    } else {
      this.citiesService.newCity(city)
        .subscribe(res => {
          console.log('new', res);
          this.cities.push(res);
        })
    }
  }

}
