import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { routerModule } from "./users.routing";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UtilitiesModule } from "../utilities/utilities.module";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


import { UsersComponent } from './users.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UsersService } from '../api/users.service';
import { UserCodesComponent } from './user-codes/user-codes.component';
import { PromotionalCodesComponent } from './promotional-codes/promotional-codes.component';
import { PromotionalCodesFormComponent } from './promotional-codes-form/promotional-codes-form.component'

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    FormsModule,
    NgbModule,
    UtilitiesModule,
    NgxDatatableModule
  ],
  providers: [
    UsersService
  ],
  declarations: [UsersComponent, UsersListComponent, UserFormComponent, UserDetailsComponent, UserCodesComponent, PromotionalCodesComponent, PromotionalCodesFormComponent]
})
export class UsersModule { }
