import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routerModule } from "./services.routing";
import { FormsModule } from '@angular/forms';
import { ServicesService } from "../api/services.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UtilitiesModule } from "../utilities/utilities.module";
import { OrderModule } from 'ngx-order-pipe';
import { MomentModule } from 'angular2-moment';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ServiceAvailabilityService } from "../api/service-availability.service";
import { ServiceUnavailabilityService } from "../api/service-unavailability.service";

import { ServicesListComponent } from './services-list/services-list.component';
import { ServicesComponent } from './services.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ServiceFormComponent } from './service-form/service-form.component';
import { ServiceAvailabilityComponent } from "./service-availability/service-availability.component";
import { ServiceHoursModalComponent } from "./service-hours-modal/service-hours-modal.component";
import { ServiceUnavailabilityComponent } from './service-unavailability/service-unavailability.component';
import { ServiceClosedModalComponent } from './service-closed-modal/service-closed-modal.component';
import { ServicePricesComponent } from './service-prices/service-prices.component';
import { ServicePricesEditComponent } from './service-prices-edit/service-prices-edit.component';
import { ServiceChangesCheckService } from './service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from './service-changes-validation/service-changes-validation.service';
import { ServiceServicesListComponent } from './service-services-list/service-services-list.component';
import { ServiceFilterComponent } from './service-filter/service-filter.component';
import { ServiceVariantsComponent } from './service-variants/service-variants.component';
import { ServiceVariantComponent } from './service-variant/service-variant.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    NgbModule,
    FormsModule,
    UtilitiesModule,
    OrderModule,
    MomentModule,
    NgxDatatableModule
  ],
  exports: [
    ServiceServicesListComponent
  ],
  entryComponents: [
    ServiceHoursModalComponent,
    ServiceClosedModalComponent
  ],
  providers: [
    ServicesService,
    ServiceAvailabilityService,
    ServiceUnavailabilityService,
    ServiceChangesCheckService,
    ServiceChangesValidationService
  ],
  declarations: [ServicesListComponent, ServicesComponent, ServiceDetailsComponent, ServiceFormComponent, ServiceAvailabilityComponent, ServiceHoursModalComponent, ServiceUnavailabilityComponent, ServiceClosedModalComponent, ServicePricesComponent, ServicePricesEditComponent, ServiceServicesListComponent, ServiceFilterComponent, ServiceVariantsComponent, ServiceVariantComponent]
})
export class ServicesModule { }
