import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardService } from '../api/dashboard.service'

import { routerModule } from "./dashboard.routing";

@NgModule({
  imports: [
    CommonModule,
    routerModule,
  ],
  declarations: [DashboardComponent],
  providers: [DashboardService]
})
export class DashboardModule { }
