import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProfilesService } from './../../api/profiles.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'profiles-filter',
  templateUrl: './profiles-filter.component.html',
  styleUrls: ['./profiles-filter.component.scss']
})

export class ProfilesFilterComponent implements OnInit {
  @Input() q;
  
  @Output() query = new EventEmitter();

  modelChanged: Subject<string> = new Subject<string>();

  constructor(private profilesService:ProfilesService) { }

  ngOnInit() {
    this.modelChanged
      .debounceTime(600)
      .distinctUntilChanged()
      .subscribe(res => {
        this.query.emit(this.q);
      })
  }

  updateModel(property) {
    this.modelChanged.next(property);
  }
}