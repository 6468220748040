import { UIRouterModule } from "@uirouter/angular";

import { CompaniesComponent } from "./companies.component";
import { CompaniesListComponent } from "./companies-list/companies-list.component";
import { CompaniesDetailsComponent } from './companies-details/companies-details.component';
import { CompaniesFormComponent } from './companies-form/companies-form.component';

const routesConfig = {
  states: [
    {
      name: 'companies',
      redirectTo: 'companies.list',
      component:  CompaniesComponent,
      data: {requiresAuth: true}
    },
    {
      name: 'companies.list',
      url: '/companies',
      component:  CompaniesListComponent,
      data: {requiresAuth: true}
    },
    {
      name: 'companies.details',
      url: '/companies/:id',
      component:  CompaniesDetailsComponent,
      data: {requiresAuth: true}
    },
    {
      name: 'companies.new',
      url: '/companies/new',
      component: CompaniesFormComponent,
      data: {requiresAuth: true}
    },
    {
      name: 'companies.edit',
      url: '/companies/edit/:id',
      component: CompaniesFormComponent,
      data: {requiresAuth: true}
    }
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)