import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { Angular2TokenService } from 'angular2-token';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  loggedIn = true;
  navItems = [
    {
      name: 'Dashboard',
      state: 'dashboard',
      icon: 'ion-ios-apps'
    },
    {
      name: 'Agregacja',
      state: 'agregations',
      icon: 'ion-logo-buffer'
    },
    {
      name: 'Użytkownicy',
      state: 'users',
      icon: 'ion-ios-people'
    },
    {
      name: 'Profile',
      state: 'profiles',
      icon: 'ion-md-list-box'
    },
    {
      name: 'Usługi',
      state: 'services',
      icon: 'ion-ios-briefcase'
    },
    {
      name: 'Recenzje',
      state: 'reviews',
      icon: 'ion-ios-book'
    },
    {
      name: 'Dyscypliny',
      state: 'disciplines',
      icon: 'ion-ios-basketball'
    },
    {
      name: 'Miasta',
      state: 'cities',
      icon: 'ion-ios-home'
    },
    {
      name: 'Rezerwacje',
      state: 'bookings',
      icon: 'ion-ios-bookmarks'
    },
    {
      name: 'Rezerwacje telefoniczne',
      state: 'callendar-bookings',
      icon: 'ion-ios-bookmarks'
    },
    {
      name: 'Firmy',
      state: 'companies',
      icon: 'ion-ios-home'
    },
    {
      name: 'Blog',
      state: 'blog',
      icon: 'ion-ios-book'
    },
    {
      name: 'Ustawienia',
      state: 'settings.resetPassword',
      icon: 'ion-ios-cog'
    }
  ];

  constructor(private stateService:StateService,
              private _tokenService: Angular2TokenService,
              private toastr: ToastrService) { }

  ngOnInit() { }

  dropDown() {
    document.getElementById("logout").style.height = "75px";
  }

  dropDownEnd() {
    document.getElementById("logout").style.height = "0px";
  }

  signOut(){
    this._tokenService.signOut().subscribe(
      res => {
        this.toastr.success("You've been loged out.", "Success!");
        this.stateService.go('auth')
      },
      err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      }
    );
  }

  menuAction() {
    let menu = document.getElementById("menu");
    let content = document.getElementById("content");
    if (menu.getAttribute("class") == "sidenav__opened") {
      menu.setAttribute("class", "sidenav__closed")
      content.style.marginLeft = "70px"
    } else {
        menu.setAttribute("class", "sidenav__opened");
        content.style.marginLeft = "270px"
      }
  }
}
