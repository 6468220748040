import { Component } from '@angular/core';

@Component({
  selector: 'callendar-bookings',
  template: `
  <div class="container-fluid">
    <div class="row">
      <div class="col py-2">
        <div class="card">
          <div class="card-body">
            <callendar-bookings-search (onFind)="service = $event"></callendar-bookings-search>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col py-2">
        <div class="card">
          <div class="card-body">
            <callendar-bookings-callendar [service]="service"></callendar-bookings-callendar>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: [`
    .card {
      z-index: initial !important;
    }
  `]
})
export class CallendarBookingsComponent {

  public _service: any;

  constructor() { }


  set service(service) {
    this._service = service;
  }

  get service() {
    return this._service;
  }
}
