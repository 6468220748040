import { UIRouterModule } from "@uirouter/angular";
import { CallendarBookingsComponent } from './callendar-bookings.component';


const routesConfig = {
  states: [
    {
      name: 'callendar-bookings',
      url: '/callendar-bookings',
      component:  CallendarBookingsComponent,
      data: {requiresAuth: true}
    }
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)