import { UIRouterModule } from "@uirouter/angular";
import { ServicesComponent } from './services.component';
import { ServicesListComponent } from './services-list/services-list.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ServiceFormComponent } from './service-form/service-form.component';
import { ServicePricesEditComponent } from "./service-prices-edit/service-prices-edit.component";

const routesConfig = {
  states: [
    { name: 'services', redirectTo: 'services.list', component:  ServicesComponent, data: {requiresAuth: true}},
    { name: 'services.list', url: '/services?name&address&user&status&promoted&page&sort&servicePlus', component:  ServicesListComponent, data: {requiresAuth: true}},
    { name: 'services.listVerified', url: '/services/verified', component:  ServicesListComponent, data: {query: {status: "verified"}, requiresAuth: true}},
    { name: 'services.listPending', url: '/services/pending', component:  ServicesListComponent, data: {query: {status: "pending"},requiresAuth: true}},
    { name: 'services.listRejected', url: '/services/rejected', component:  ServicesListComponent, data: {query: {status: "rejected"},requiresAuth: true}},
    { name: 'services.listPromoted', url: '/services/promoted', component:  ServicesListComponent, data: {query: {status: "promoted"},requiresAuth: true}},
    { name: 'services.servicePlus', url: '/services/servicePlus', component:  ServicesListComponent, data: {query: {status: "is_service_plus"},requiresAuth: true}},
    { name: 'services.listPage', url: '/services/page/:pageNumber', component:  ServicesListComponent, data: {requiresAuth: true}},
    { name: 'services.listPageVerified', url: '/services/verified/page/:pageNumber', component:  ServicesListComponent, data: {query: {status: "verified"},requiresAuth: true}},
    { name: 'services.listPagePending', url: '/services/pending/page/:pageNumber', component:  ServicesListComponent, data: {query: {status: "pending"},requiresAuth: true}},
    { name: 'services.listPageRejected', url: '/services/rejected/page/:pageNumber', component:  ServicesListComponent, data: {query: {status: "rejected"},requiresAuth: true}},
    { name: 'services.new', url: '/services/new', component:  ServiceFormComponent, data: {requiresAuth: true}},
    { name: 'services.details', url: '/services/:id', component:  ServiceDetailsComponent, data: {requiresAuth: true}},
    { name: 'services.edit', url: '/services/:id/edit', component:  ServiceFormComponent, data: {requiresAuth: true}},
    { name: 'services.editLocation', url: '/services/:id/edit/location', component:  ServiceFormComponent, data: {requiresAuth: true}},
    { name: 'services.editPrices', url: '/services/:id/edit/prices', component:  ServicePricesEditComponent, data: {requiresAuth: true}},
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
