import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { routerModule } from "./settings.routing";
import { SettingsComponent } from './settings.component'
import { ProfileSafetyComponent } from './profile-safety/profile-safety.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routerModule
  ],
  declarations: [SettingsComponent, ProfileSafetyComponent]
})
export class SettingsModule { }
