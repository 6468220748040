import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Angular2TokenService } from 'angular2-token';
import { StateService } from "@uirouter/angular";
import { Http } from '@angular/http';
import * as Moment from "moment";
import { environment } from 'environments/environment';

import { DisciplinesService } from './../api/disciplines.service';
import { AgregationsService } from './../api/agregations.service';

@Component({
  selector: 'agregations',
  templateUrl: './agregations.component.html',
  styleUrls: ['./agregations.component.scss']
})
export class AgregationsComponent implements OnInit {

  website: string = "";
  service: any;
  weekdays = [
    {
      name: "Poniedziałek",
      engName: "monday",
      selected: true,
      value: 64
    },
    {
      name: "Wtorek",
      engName: "tuesday",
      selected: true,
      value: 32
    },
    {
      name: "Środa",
      engName: "wednesday",
      selected: true,
      value: 16
    },
    {
      name: "Czwartek",
      engName: "thursday",
      selected: true,
      value: 8
    },
    {
      name: "Piątek",
      engName: "friday",
      selected: true,
      value: 4
    },
    {
      name: "Sobota",
      engName: "saturday",
      selected: true,
      value: 2
    },
    {
      name: "Niedziela",
      engName: "sunday",
      selected: true,
      value: 1
    },
  ];
  timesFrom = [];
  timesTo = [];
  savingInProgress:boolean = false;
  errors = false;
  picturePreview;
  pictureUrl;
  disciplines:Array<object>;
  disciplineSelected: number;
  isScraping: boolean = false;

  constructor(
    private toastr: ToastrService,
    public _tokenService: Angular2TokenService,
    private disciplinesService: DisciplinesService,
    private agregationsService: AgregationsService,
    private http: Http,
    private stateService: StateService
  ) { }

  ngOnInit() {
    this.service = this.emptyService();
    this.createTimesFrom(this.service.interval, '00:00', '24:00');
    this.createTimesTo(this.service.interval, '00:00', '24:00');
    this.disciplinesService.getDisciplines().subscribe(
      (response) => {
        this.disciplines = response;
      }
    );
  }

  emptyService() {
    let service = {
      name: '',
      description: '',
      phone: '',
      email: '',
      interval: 60,
      location: '',
      city_id: 1,
      openTime: '00:00',
      closeTime: '24:00',
      price: '',
      addresses_attributes: [],
      disciplineSelected: false,
      pictureSelected: false,
      suggested_discipline: "",
      category: "facility",
      pictures: [],
    };
    return service;
  }

  addPictureFromUrl() {
    if (!this.pictureUrl) { return }
    this.service.pictures.push({url: this.pictureUrl, selected: true})
    this.checkSelectedPictures();
    this.pictureUrl = ''
  }

  createTimesFrom(interval, from, to) {
    this.timesFrom = [];
    let fromMoment = Moment(from, "HH:mm");
    let toMoment = Moment(to, "HH:mm");

    let t = toMoment.diff(fromMoment, 'm') / interval;
    while (t--) {
      this.timesFrom.push(fromMoment.format("HH:mm"));
      fromMoment.add(interval, 'minute');
    }
  }

  createTimesTo(interval, from, to) {
    this.timesTo = [];
    let fromMoment = Moment(from, "HH:mm");
    let toMoment = Moment(to, "HH:mm");

    let t = toMoment.diff(fromMoment, 'm') / interval;
    fromMoment.add(interval, 'minute');
    while (t--) {
      if (t < 1) {
        this.timesTo.push(fromMoment.format("kk:mm"));
      } else {
        this.timesTo.push(fromMoment.format("HH:mm"));
      }
      fromMoment.add(interval, 'minute');
    }
  }

  resetService() {
    this.service = this.emptyService();
    this.errors = false;
    this.picturePreview = null;
    this.unselectDisciplines();
  }

  addPicture(picture) {
    picture.selected = true;
    this.checkSelectedPictures();
  }

  removePicture(picture) {
    picture.selected = false;
    this.checkSelectedPictures();
  }

  checkSelectedPictures() {
    let selected = false;
    for (let picture of this.service.pictures) {
      if (picture['selected']) {
        selected = true;
      }
    }
    this.service.pictureSelected = selected;
  }

  checkSelectedDisciplines() {
    let selected = false;
    for (let discipline of this.disciplines) {
      if (discipline['selected']) {
        if (discipline['id'] > 0 || (discipline['id'] == 0 && this.service.suggested_discipline != "")) {
          selected = true;
        }
      }
    }
    this.service.disciplineSelected = selected;
  }

  unselectDisciplines() {
    for (let discipline of this.disciplines) {
      discipline['selected'] = false;
    }
    this.service.disciplineSelected = false;
  }

  save(form, service) {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;

    this.errors = false;

    if (!this.service.name || !this.service.description || !this.service.disciplineSelected || !this.service.pictureSelected) {
      this.toastr.warning("Upewnij się, że wypełniłeś wszystkie dane.");
      this.errors = true;
      this.savingInProgress = false;
      return;
    }

    if (this.website) {
      service["agregated_from"] = this.website;
    }

    this.agregationsService.createProfile(service, this.disciplines, this.weekdays, this.service.pictures, 0, 0)
      .subscribe( (service) => {
        console.info(service)
        this.savingInProgress = false;
        this.toastr.success("this.translatePipe.transform(_i18n('facilities.form.toast-messages.success-message'))", 'Success!');
        this.stateService.go('profiles.details', {id: service.id})
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
        this.savingInProgress = false;
      })
  }

  setAddresses(event) {
    this.service.addresses_attributes = event;
  }

  crawl() {
    this.isScraping = true;
    this.resetService();
    this.agregationsService.getData(this.website).subscribe(
      (response) => {
        for (let key in response) {
          this.service[key] = response[key];
        }
        this.isScraping = false;
      }
    )
  }
}
