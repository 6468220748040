import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'app/api/company.service';
import { Company } from '../companies.interface';

@Component({
  selector: 'companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {

  public companies: Company[] = [];
  public page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };

  constructor(private companyService: CompanyService) { }

  ngOnInit() {
    this.getAllCompanies(this.page.size + 1);
  }

  private getAllCompanies(page: number) {
    this.companyService.getAllCompanies(page).subscribe(
      res => {
        this.page.totalElements = res.size;
        this.page.size = res.per_page;
        this.companies = res.data;
      },
      err => console.log(err)
    )
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
  }

  public remove(id: number) {
    if (confirm('Czy na pewno chcesz usunąć tą firmę')) {
      this.companyService.deleteCompany(id).subscribe(
        res => {
          console.log(res);
        },
        err => console.log(err)
      )
    }
  }
}
