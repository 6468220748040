import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ServicesService } from './../../api/services.service';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'service-filter',
  templateUrl: './service-filter.component.html',
  styleUrls: ['./service-filter.component.scss']
})

export class ServiceFilterComponent implements OnInit {
  @Input() q;
  
  @Output() query = new EventEmitter();

  modelChanged: Subject<string> = new Subject<string>();

  constructor(private servicesService: ServicesService) { }

  ngOnInit() {
    this.modelChanged
      .debounceTime(600)
      .distinctUntilChanged()
      .subscribe(res => {
        this.query.emit(this.q);
      });
  }

  updateModel(property) {
    this.modelChanged.next(property);
  }
}