import { UIRouterModule } from "@uirouter/angular";
import { UsersComponent } from "./users.component";
import { UsersListComponent } from "./users-list/users-list.component";
import { UserDetailsComponent } from "./user-details/user-details.component";
import { UserFormComponent } from "./user-form/user-form.component";
import { PromotionalCodesFormComponent } from "./promotional-codes-form/promotional-codes-form.component";
import { Angular2TokenService } from 'angular2-token';

const routesConfig = {
  states: [
    { name: 'users', redirectTo: 'users.list', component:  UsersComponent, data: {requiresAuth: true}},
    { name: 'users.list', url: '/users', component:  UsersListComponent, data: {requiresAuth: true}},
    { name: 'users.listPage', url: '/users/page/:pageNumber', component:  UsersListComponent, data: {requiresAuth: true}},
    { name: 'users.new', url: '/users/new', component:  UserFormComponent, data: {requiresAuth: true}},
    { name: 'users.details', url: '/users/:id', component:  UserDetailsComponent, data: {requiresAuth: true}},
    { name: 'users.edit', url: '/users/:id/edit', component:  UserFormComponent, data: {requiresAuth: true}},
    { name: 'users.promotional_codes', url: '/users/:user_id/promotional_codes/:id', component:  PromotionalCodesFormComponent, data: {requiresAuth: true}},
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
