import { Injectable} from '@angular/core';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../services/service-changes-check/service-changes-check.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckModalComponent } from '../../utilities/check-modal/check-modal.component';
import { Observable, Subject } from 'rxjs/Rx';

@Injectable()
export class ServiceChangesValidationService {
  resolved = false
  resolvedWith: boolean;

  constructor(private checkChangesService: ServiceChangesCheckService,
              private transitionService: TransitionService,
              private modalService: NgbModal) {}

              validate(object) {
                  if (this.checkObject(object) == true) {
                    return true;
                  } else if (this.resolved) {
                    return this.resolvedWith;
                  } else {
                    let cos:HookResult = new Promise((resolve, reject) => {
                      this.openEditModal()
                      .then((res) => {
                        if (res) {
                          this.resolved = true;
                          this.resolvedWith = true;
                          resolve(true);
                        } else {
                          this.resolved = true;
                          this.resolvedWith = false;
                          resolve(false);
                        }
                      })
                    })
                    return cos;
                }
              }

              checkObject(object) {
                return this.checkChangesService.checkObject(object);
              }

              resetResolved() {
                this.resolved = false;
              }

              openEditModal(): Promise<boolean> {
                return new Promise((resolve, reject) => {
                  var modalRef = this.modalService.open(CheckModalComponent);
                  modalRef.componentInstance.decision
                  .subscribe((decision) => {
                    if (decision == true) {
                      return resolve(true);
                    } else {
                      return resolve(false);
                    }
                  })
                });
              }

              ngOnDestroy() {
                this.resolved = false;
                this.resolvedWith = undefined;
              }
}
