import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'reject-service-modal',
  templateUrl: './reject-service-modal.component.html',
  styleUrls: ['./reject-service-modal.component.scss']
})
export class RejectServiceModalComponent implements OnInit {

  @Output()
  reason: EventEmitter<any> = new EventEmitter<any>();
  
  reject = {
    title: '',
    content: ''
  };
  constructor(private modalInstace: NgbActiveModal) { }

  ngOnInit() {
  }

  close(){
    this.modalInstace.close();
  }

  continue() {
    this.reason.emit(this.reject);
    this.close();
  }

}
