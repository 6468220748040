import { Component, OnInit } from '@angular/core';
import { ReviewsService } from './../../api/reviews.service';
import { UIRouter } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'review-detail',
  templateUrl: './review-detail.component.html',
  styleUrls: ['./review-detail.component.scss']
})
export class ReviewDetailComponent implements OnInit {
  review;
  constructor(
    private route: UIRouter,
    private reviewsService: ReviewsService,
    private toastr: ToastrService,
  ) {
    this.getReview();
  }

  ngOnInit() {
  }

  getReview() {
    let id = this.route.globals.params.id;
    this.reviewsService.getReview(id)
      .subscribe(review => {
        this.review = review;
        if (review.reviewable.description.length > 300) {
          review.reviewable.description = review.reviewable.description.slice(0, 300) + '...';
        }
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
  }

  verifyReview() {
    let id = this.route.globals.params.id;
    this.reviewsService.verifyReview(id)
      .subscribe(review => {
        this.review = review;
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
  }

  rejectReview() {
    let id = this.route.globals.params.id;
    this.reviewsService.rejectReview(id)
      .subscribe(review => {
        this.review = review;
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
  }

  verifyResponse() {
    let id = this.route.globals.params.id;
    this.reviewsService.verifyResponse(id)
      .subscribe(review => {
        this.review = review;
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
  }

  rejectResponse() {
    let id = this.route.globals.params.id;
    this.reviewsService.rejectResponse(id)
      .subscribe(review => {
        this.review = review;
      }, err => {
        this.toastr.error(err.statusText, err.status);
      })
  }

}
