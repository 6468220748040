import { Injectable } from '@angular/core';

@Injectable()
export class ServiceChangesCheckService {
  oldObject: any;

  constructor() { }

  assignObject(object) {
    this.oldObject = { ...object}
  }

  checkObject(newObject) {
    if (JSON.stringify(newObject) === JSON.stringify(this.oldObject)) {
      return true;
    } else {
      return false;
    }
  }


}
