import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Discipline } from "../../api/disciplines.service";

@Component({
  selector: 'disciplines-list',
  templateUrl: './disciplines-list.component.html',
  styleUrls: ['./disciplines-list.component.scss']
})
export class DisciplinesListComponent implements OnInit {
  
  @Input()
  disciplines: Array<Discipline>;

  @Output()
  disciplineSelected = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  selectDiscipline(disciplines) {
    this.disciplineSelected.emit(disciplines);
    console.log(this.disciplines);
  }
}
