import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routerModule } from './callendar-bookings.routing';
import { CallendarBookingsComponent } from './callendar-bookings.component';
import { CallendarBookingsSearchComponent } from './callendar-bookings-search/callendar-bookings-search.component';
import { ServicesService } from 'app/api/services.service';
import { NgbTypeaheadModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CallendarBookingsCallendarComponent } from './callendar-bookings-callendar/callendar-bookings-callendar.component';
import { CallendarBookingsCallendarHeaderComponent } from './callendar-bookings-callendar-header/callendar-bookings-callendar-header.component';
import { CallendarBookingsModalComponent } from './callendar-bookings-modal/callendar-bookings-modal.component';
import { UtilitiesModule } from './../utilities/utilities.module';

registerLocaleData(localePl);

@NgModule({
  declarations: [
    CallendarBookingsComponent,
    CallendarBookingsSearchComponent,
    CallendarBookingsCallendarComponent,
    CallendarBookingsCallendarHeaderComponent,
    CallendarBookingsModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routerModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    UtilitiesModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
  ],
  providers: [
    ServicesService,
  ],
  entryComponents: [
    CallendarBookingsModalComponent
  ]
})
export class CallendarBookingsModule { }
