import { UIRouterModule } from "@uirouter/angular";
import { UIRouter } from "@uirouter/core";
import { authHook } from './resolvers/auth.resolver';

const routesConfig = {
  otherwise: '/dashboard',
  states: [],
  config: routerConfigFn,
}

export const routerModule = UIRouterModule.forRoot(routesConfig)

export function routerConfigFn(router: UIRouter) {
  const transitionService = router.transitionService;
  authHook(transitionService);
}
