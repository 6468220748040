import { Component, OnInit } from '@angular/core';
import { UsersService } from "../../api/users.service";
import { UIRouter, StateService } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})

export class UsersListComponent implements OnInit {
  users = [];
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  }
  sort: any;
  q = {};
  loading = false;
  pagination;

  constructor(private usersService:UsersService,
              private route:UIRouter,
              private stateService:StateService,
              private toastr: ToastrService) {
                this.page.pageNumber = 0;
              }

  ngOnInit() {
    this.setPage({offset: 0})
}

setPage(pageInfo) {
  this.page.pageNumber = pageInfo.offset;
  this.usersService.getUsers(this.page.pageNumber+1, this.q, this.sort)
  .subscribe(response => {
    this.page.totalElements = response.size;
    this.page.size = response.per_page;
    this.users = response.data;
  })
}

onSort(event) {
  this.loading = true;
  let s = event.sorts[0];
  let prop = s.prop.replace(/\./g, "_");
  this.sort = prop + " " + s.dir;
  this.usersService.getUsers(0, this.q, this.sort)
  .subscribe(res => {
    this.users = res.data;
    this.loading = false;
  });
}

pageChange(page) {
  if (page != this.route.globals.params.pageNumber) {
    console.log(page)
    this.stateService.go('services.listPage', {pageNumber: page})
  }
}

  blockUser(users, i) {
    console.log(users, i);
    this.usersService.updateUserStatus(users.id, 'blocked')
    .subscribe(res=>{
      console.warn("THE RES")
      console.log(res)
        users.status = res.status;
    })
  }

  unblockUser(users, i) {
    this.usersService.updateUserStatus(users.id, 'active')
    .subscribe(res=>{
      console.log(res)
        users.status = res.status;
    })
  }

  remove(userId) {
    if (confirm("Are you sure?")) {
      this.usersService.deleteUser(userId)
      .subscribe( response => {
        this.toastr.success('User has been deleted.', 'Success!')
        this.setPage({ offset: 0});
      }, err => {
        console.error(err)
        this.toastr.error(err.statusText, err.status);
      })
    }
  }
}
