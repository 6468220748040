import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { routerModule } from "./cities.routing";
import { CitiesService } from "../api/cities.service";

import { CitiesComponent } from './cities.component';
import { CitiesListComponent } from './cities-list/cities-list.component';
import { CitiesFormComponent } from './cities-form/cities-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routerModule
  ],
  providers: [
    CitiesService
  ],
  declarations: [CitiesComponent, CitiesListComponent, CitiesFormComponent]
})
export class CitiesModule { }
