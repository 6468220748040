import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UIRouter, StateService } from '@uirouter/core';
import { CompanyService } from 'app/api/company.service';

@Component({
  selector: 'companies-form',
  templateUrl: './companies-form.component.html',
  styleUrls: ['./companies-form.component.scss']
})
export class CompaniesFormComponent implements OnInit {

  companyForm: FormGroup;
  previewImage: string | ArrayBuffer | File;
  errors: string[] = [];

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private route:UIRouter,
    private companyService: CompanyService,
    private stateService:StateService,
  ) { }

  ngOnInit() {
    this.companyForm = this.fb.group({
      name: ["", this.validators],
      vat_id: ["", this.validators],
      location: ["", this.validators],
      plus_assets_per_user: ['', [...this.validators, Validators.min(1)]],
      plus_enabled: [false, this.validators],
      plus_enabled_from: [''],
      plus_enabled_to: [''],
      contact_person: this.fb.group({
        first_name: ['', this.validators],
        last_name: ['', this.validators],
        email: ['', this.validators],
        phone: ['', this.validators],
      }),
      logo: [null, this.validators]
    })

    if (this.companyId) {
      this.companyService.getCompany(this.companyId).subscribe(
        res => {
          this.previewImage = res.logo;
          Object.keys(this.companyForm.controls).forEach(field => {
            if ((field === 'plus_enabled_to' || field === 'plus_enabled_from') && res[field] && res[field].length) {
              const date = res[field].split('-');
              this.companyForm.get(field).patchValue({
                year: parseInt(date[0]),
                month: parseInt(date[1]),
                day: parseInt(date[2]),
              })
            } else {
              this.companyForm.get(field).patchValue(res[field]);
            }
          });
        },
        err => console.log(err)
      )
    }
  }

  public submit() {
    this.errors = [];
    if (this.companyForm.valid) {
      if (!this.companyId) {
        this.saveCompany();
      } else {
        this.updateCompany();
      }
    } else {
      this.handleErrors();
    }
  }

  public onFileChange(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.companyForm.patchValue({
        logo: file
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewImage = reader.result;
      }
      this.cd.markForCheck();
    }
  }

  private saveCompany() {
    let data = this.companyForm.value;
    data.plus_enabled_from = `${data.plus_enabled_from.year}-${data.plus_enabled_from.month}-${data.plus_enabled_from.day}`;
    data.plus_enabled_to = `${data.plus_enabled_to.year}-${data.plus_enabled_to.month}-${data.plus_enabled_to.day}`;
    this.companyService.addCompany(data).subscribe(
      res => {
        this.stateService.go('companies.list');
      },
      err => this.errors = err
    )
  }

  private updateCompany() {
    let data = this.companyForm.value;
    data.plus_enabled_from = `${data.plus_enabled_from.year}-${data.plus_enabled_from.month}-${data.plus_enabled_from.day}`;
    data.plus_enabled_to = `${data.plus_enabled_to.year}-${data.plus_enabled_to.month}-${data.plus_enabled_to.day}`;
    if (typeof data.logo === 'string') {
      delete data.logo;
    }
    this.companyService.updateCompany(this.companyId, data).subscribe(
      res => {
        this.stateService.go('companies.list');
      },
      err => this.errors = err
    )
  }

  private handleErrors() {
    Object.keys(this.companyForm.controls).forEach(field => {
      if (this.companyForm.get(field).errors) {
        this.errors.push(`pole ${field} jest wymagane`)
      }
    });
  }

  get companyId() {
    return this.route.globals.params.id;
  }


  get validators() {
    return !this.companyId ? [Validators.required] : [];
  }

}
