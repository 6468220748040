import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceClosedModalComponent } from "../service-closed-modal/service-closed-modal.component";
import { ServiceUnavailabilityService } from "../../api/service-unavailability.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'service-unavailability',
  templateUrl: './service-unavailability.component.html',
  styleUrls: ['./service-unavailability.component.scss']
})
export class ServiceUnavailabilityComponent implements OnInit {

  @Input()
  serviceId;

  dates = [];

  constructor(private modalService: NgbModal,
              private blockService: ServiceUnavailabilityService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.blockService.getBlocks(this.serviceId)
      .subscribe( res => {
        console.log(res)
        this.dates = res.data;
      })
  }

  remove(date) {
    if (confirm('Are you sure?')) {
      this.blockService.removeBlock(this.serviceId, date.id)
      .subscribe(res => {
        this.dates.splice(date, 1);
        this.toastr.success('Removed.', 'Success!')
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
    }
  }

  openEditModal(date) {
    const modalRef = this.modalService.open(ServiceClosedModalComponent, {size: 'lg'});
    modalRef.componentInstance.date = date;
    modalRef.componentInstance.newDate
      .subscribe((date) => {
        console.log(date)
        if (date.new) {
          this.blockService.newBlock(this.serviceId, date)
            .subscribe(res => {
              this.dates.push(res);
              this.toastr.success('New unavailabilities added.', 'Success!')
            }, err => {
              console.error(err);
              this.toastr.error(err.statusText, err.status);
            })
        } else {
          this.blockService.editBlock(this.serviceId, date)
            .subscribe(res => {
              this.toastr.success('Changes saved.', 'Success!')
            }, err => {
              console.error(err);
              this.toastr.error(err.statusText, err.status);
            })
        }
      })
  }

}
