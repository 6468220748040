import { TransitionService } from '@uirouter/angular';
import { Angular2TokenService } from 'angular2-token';
import { Http } from '@angular/http';

export function authHook(transitionService: TransitionService) {

  const requiresAuthCriteria = {
    to: (state) => state.data && state.data.requiresAuth
  };

  transitionService.onStart(requiresAuthCriteria, (transition) => {
    const http: Http = transition.injector().get(Http)
    const authService: Angular2TokenService = transition.injector().get(Angular2TokenService);
    const stateService = transition.router.stateService;

    return new Promise((resolve, reject) => {
      authService.validateToken().subscribe(
        res => resolve(),
        error => reject()
      );
    })
    .then(() => {})
    .catch(() => {
      authService.signOut();
      stateService.go('auth.login',{ sessionExpired: true });
    });
  });
}
