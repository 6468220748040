import { Component, OnInit } from '@angular/core';
import { ServicesService } from "../../api/services.service";
import { UIRouter, StateService } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { Angular2TokenService } from 'angular2-token';

@Component({
  selector: 'services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss']
})

export class ServicesListComponent implements OnInit {
  services = [];
  query: any = {};
  queryParams: any = [
    {k: "name", v: "name_cont"},
    {k: "address", v: "addresses_formatted_address_or_location_cont"},
    {k: "user", v: "users_first_name_or_users_last_name_or_users_email_cont"},
    {k: "status", v: "verification_status_eq"},
    {k: "promoted", v: "is_promoted_eq"},
    {k: 'servicePlus', v:'is_service_plus_eq'}
  ];
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  sort: any;
  tableSort: any = [];

  constructor(private stateService:StateService,
              private route:UIRouter,
              private servicesService: ServicesService,
              private toastr: ToastrService,
              private _tokenService: Angular2TokenService) {
                this.setQueryParams();
                this.setListParams();
                this.getServices();
              }

  ngOnInit() { }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.changeLocation();
  }

  onSort(event) {
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.sort = prop + " " + s.dir;
    this.page.pageNumber = 0;
    this.changeLocation();
  }

  remove(serviceId) {
    if (confirm("Are you sure?")) {
      this.servicesService.deleteService(serviceId).subscribe(
        response => {
          this.toastr.success('Service have been deleted.', 'Success!');
          this.getServices();
        },
        err => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
        }
      )
    }
  }

  togglePromoted(service) {
    if (confirm("Are you sure?")) {
      service.is_promoted = !service.is_promoted;
      this.servicesService.editService({id: service.id, is_promoted: service.is_promoted}).subscribe(
        response => {
          this.toastr.success('Service have been added to promoted list.', 'Success!');
        },
        err => {
          console.error(err);
          service.is_promoted = !service.is_promoted;
          this.toastr.error(err.statusText, err.status);
        }
      )
    }
  }

  getServices() {
    this.servicesService.getPagedServices(this.page.pageNumber + 1, this.query, this.sort).subscribe(
      response => {
        this.page.totalElements = response.size;
        this.page.size = response.per_page;
        this.services = response.data;
      }
    )
  }

  onQueryChange(event) {
    this.query = {...this.query, ...event};
    this.changeLocation();
  }

  changeLocation() {
    let params = this.prepareQueryParams();
    this.stateService.transitionTo('services.list', params);
  }

  setQueryParams() {
    for (let param of this.queryParams) {
      this.query[param.v] = this.route.globals.params[param.k] || '';
    }
  }

  setListParams() {
    let params = this.route.globals.params;
    this.page.pageNumber = params.page ? parseInt(params.page) : 0;
    if (params.sort) {
      this.sort = decodeURIComponent(params.sort);
      this.tableSort = [{prop: this.sort.split(' ')[0], dir: this.sort.split(' ')[1]}];
    } else {
      this.sort = '';
      this.tableSort = [];
    }
  }

  prepareQueryParams() {
    let q = {};
    for (let param of this.queryParams) {
      if (this.query[param.v]) {
        q[param.k] = this.query[param.v];
      }
    }
    q["page"] = this.page.pageNumber;
    q["sort"] = this.sort;
    return q;
  }
}
