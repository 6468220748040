import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
 
import { routerModule } from "./disciplines.routing";
import { DisciplinesService } from "../api/disciplines.service";

import { DisciplinesComponent } from './disciplines.component';
import { DisciplinesListComponent } from './disciplines-list/disciplines-list.component';
import { DisciplinesFormComponent } from './disciplines-form/disciplines-form.component';

@NgModule({
  imports: [ 
    CommonModule,
    FormsModule,
    routerModule,
    NgxDatatableModule
  ],
  providers: [  
    DisciplinesService
  ],
  declarations: [DisciplinesComponent, DisciplinesListComponent, DisciplinesFormComponent]
})
export class DisciplinesModule { }
