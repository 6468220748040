import { Injectable } from '@angular/core';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../environments/environment";

@Injectable()
export class ServiceAvailabilityService {

  constructor(private _tokenService: Angular2TokenService) { }

  getAvailability(serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/availability`;
    return this._tokenService.get(url)
            .map(res => res.json())
            .map( (res) => {
              let arr = [];
              for (let key in res.hours) {
                arr.push({
                  name: key,
                  open: res.hours[key].open,
                  id: res.hours[key].id,
                  range: res.hours[key].range,
                  available: res.hours[key].available,
                  prices: res.hours[key].prices
                })
              }
              res.hours = arr;
              return res
            })
  }

  updateAvailability(serviceId, availability) {
    let avObject = {
      days: availability.days,
      interval: availability.interval,
      hours: {}
    }

    for (let i = 0; i < availability.hours.length; i++) {
      avObject.hours[availability.hours[i].name] = {};
      avObject.hours[availability.hours[i].name].open = availability.hours[i].open;
      avObject.hours[availability.hours[i].name].range = availability.hours[i].range;
      avObject.hours[availability.hours[i].name].available = availability.hours[i].available;
      avObject.hours[availability.hours[i].name].id = availability.hours[i].id;
      avObject.hours[availability.hours[i].name].prices = availability.hours[i].prices;
    }

    let url = `${environment.apiEndpoint}/services/${serviceId}/availability`;
    return this._tokenService.put(url, avObject)
            .map(res => res.json())
  }

}
