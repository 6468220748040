import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'service-services-list',
  templateUrl: './service-services-list.component.html',
  styleUrls: ['./service-services-list.component.scss']
})
export class ServiceServicesListComponent implements OnInit {
  @Input()
  services;
  
  constructor() { }

  ngOnInit() { }
  
}
