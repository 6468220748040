import { UIRouterModule } from "@uirouter/angular";
import { AgregationsComponent } from "./agregations.component";

const routesConfig = {
  states: [
    { name: 'agregations', url: '/agregations', component:  AgregationsComponent, data: {requiresAuth: true}},
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
