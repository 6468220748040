import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routerModule } from "./bookings.routing";
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'angular2-moment';
import { UtilitiesModule } from "../utilities/utilities.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { BookingsService } from "../api/bookings.service";

import { BookingsComponent } from './bookings.component';
import { BookingsListComponent } from './bookings-list/bookings-list.component';
import { BookingFormComponent } from './booking-form/booking-form.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    FormsModule,
    MomentModule,
    UtilitiesModule,
    NgbModule,
    NgxDatatableModule
  ],
  providers: [
    BookingsService
  ],
  declarations: [BookingsComponent, BookingsListComponent, BookingFormComponent, BookingDetailsComponent]
})
export class BookingsModule { }
