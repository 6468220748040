import { Component, OnInit, Input} from '@angular/core';
import { Angular2TokenService } from 'angular2-token';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, Observer } from 'rxjs/Rx';

@Component({
  selector: 'forgot-modal',
  templateUrl: './forgot-modal.component.html',
  styleUrls: ['./forgot-modal.component.scss']
})
export class ForgotModalComponent implements OnInit {

  error;
  completed: boolean;

  public email: Subject<any>
  activeEmail: any;

  @Input()
  message = "Czy jesteś pewny, że chcesz zresetować hasło?";

  constructor(private _tokenService: Angular2TokenService,
              public activeModal: NgbActiveModal) {
                this.email = new Subject();
                Observable.forkJoin([
                  this.email
                ]).subscribe(([email]) => {
                  this.activeEmail = email;
                })
      }


  ngOnInit() {
  }

  remindPassword() {
    console.log('resetuje')
    console.log(this.activeEmail)
    this._tokenService.resetPassword({email: this.activeEmail})
      .subscribe(
        res => {
          this.completed = true;
          this.message = `Wysłaliśmy maila na adres ${this.activeEmail} z instrukcją odzyskania hasła.`
        },
        error => {
          this.error = JSON.parse(error._body);
          console.log(this.error)
        }
      )
  }

  close() {
    this.activeModal.close();
  }

}
