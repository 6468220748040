import { Injectable } from '@angular/core';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../environments/environment";

@Injectable()
export class ReviewsService {

  constructor(private _tokenService: Angular2TokenService) { }
  
  get(page, query, sort) {
    if (!query) { query = {}}
    let q = "";
    let s = "";
    
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      } 
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    
    let url = `${environment.apiEndpoint}/reviews?page=${page}${q}`;
    return this._tokenService.get(url)
            .map( response => response.json() )
  }
  
  getReview(id) {
    let url = `${environment.apiEndpoint}/reviews/${id}`;
    return this._tokenService.get(url)
            .map( response => response.json() )
  }
  
  verifyReview(id) {
    let url = `${environment.apiEndpoint}/reviews/${id}/verify_review`;
    return this._tokenService.put(url, {})
            .map( response => response.json() )
  }
  
  rejectReview(id) {
    let url = `${environment.apiEndpoint}/reviews/${id}/reject_review`;
    return this._tokenService.put(url, {})
            .map( response => response.json() )
  }
  
  verifyResponse(id) {
    let url = `${environment.apiEndpoint}/reviews/${id}/verify_response`;
    return this._tokenService.put(url, {})
            .map( response => response.json() )
  }
  
  rejectResponse(id) {
    let url = `${environment.apiEndpoint}/reviews/${id}/reject_response`;
    return this._tokenService.put(url, {})
            .map( response => response.json() )
  }

}
