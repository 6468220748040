import { UIRouterModule } from "@uirouter/angular";
import { CitiesComponent } from "./cities.component";

const routesConfig = {
  states: [
    { name: 'cities', url: '/cities', component:  CitiesComponent, data: {requiresAuth: true}}
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
