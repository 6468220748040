import { UIRouterModule } from "@uirouter/angular";
import { SettingsComponent } from "./settings.component";
import { ProfileSafetyComponent } from "./profile-safety/profile-safety.component";

const routesConfig = {
  states: [
    { name: 'settings', url: '/settings', component:  SettingsComponent, data: {requiresAuth: true}},
    { name: 'settings.resetPassword', url: '/password/reset/?token', component:  ProfileSafetyComponent, data: {requiresAuth: true}}
  ]
}

export const routerModule = UIRouterModule.forChild(routesConfig)
