import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'app/api/company.service';


@Component({
  selector: 'add-employees-modal',
  templateUrl: './add-employees-modal.component.html',
  styleUrls: ['./add-employees-modal.component.scss']
})
export class AddEmployeesModalComponent {

  public csvFile: File;
  public currentView: string = 'form';
  public employeesModalError: boolean = false;

  @Input()
  companyId: number;

  @Output()
  success: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private activeModal: NgbActiveModal,
    private companyService: CompanyService,
  ) { }

  public close() {
    this.activeModal.close();
  }

  public onCsvFileChange(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.csvFile = file;
    }
  }

  public submitEmployeesCsvFile() {
    this.employeesModalError = false;
    const data = {
      csv: this.csvFile
    }
    this.companyService.addCompanyEmployees(data, this.companyId).subscribe(
      res => {
        this.currentView = 'message';
      },
      err => {
        this.employeesModalError = true;
      }
    )
  }
}
