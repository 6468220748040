import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../api/users.service';
import { UIRouter } from "@uirouter/angular";

@Component({
  selector: 'user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  user: any;

  constructor(private usersService:UsersService,
              private route:UIRouter) { }

  ngOnInit() {
    let id = this.route.globals.params.id;
    this.usersService.getUser(id)
    .subscribe(user => {
      console.log(user)
      this.user = user;
    })
  }

}
