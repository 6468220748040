import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Angular2TokenService } from 'angular2-token';
import { environment } from 'environments/environment';
import { Company, PageableCompany, PageableCompanyEploeyyes } from 'app/companies/companies.interface';
import { HttpParams } from '@angular/common/http';


@Injectable()
export class CompanyService {

  constructor(private tokenService: Angular2TokenService) { }

  getAllCompanies(page: number): Observable<PageableCompany> {
    const url = `${environment.apiEndpoint}/companies?page=${page}`;
    return this.tokenService.get(url)
      .map(respone => respone.json())
 }

  getCompany(id: number): Observable<Company> {
    const url = `${environment.apiEndpoint}/companies/${id}`;
    return this.tokenService.get(url)
      .map(respone => respone.json())
  }

  addCompany(data: Company): Observable<Company> {
    const url = `${environment.apiEndpoint}/companies`;
    const { formData, headers } = this.buildFormData(data);
    return this.tokenService.post(url, formData, { headers })
      .map(respone => respone.json())
  }

  updateCompany(id: number, data: Company): Observable<Company> {
    const url = `${environment.apiEndpoint}/companies/${id}`;
    const { formData, headers } = this.buildFormData(data);
    return this.tokenService.put(url, formData, { headers })
      .map(respone => respone.json())
  }

  deleteCompany(id: number):Observable<void> {
    const url = `${environment.apiEndpoint}/companies/${id}`;
    return this.tokenService.delete(url)
      .map(respone => respone.json())
  }

  getCompanyEployees(page: number, id: number): Observable<PageableCompanyEploeyyes> {
    const url = `${environment.apiEndpoint}/companies/${id}/employees?page=${page}`;
    return this.tokenService.get(url)
      .map(respone => respone.json())
  }

  addCompanyEmployees(data: any, id: number) {
    const url = `${environment.apiEndpoint}/companies/${id}/import_users`;
    const { formData, headers } = this.buildFormData(data);
    return this.tokenService.post(url, formData, { headers })
      .map(respone => respone.json())
  }

  private buildFormData(obj: Object, form?: FormData, namespace?: string) {
    let headers = this.tokenService.currentAuthHeaders;
    headers.append('enctype',"multipart/form-data")
    let formData = form || new FormData();
    let formKey;

    for(var property in obj) {
      if(obj.hasOwnProperty(property)) {
        if(namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
        if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          this.buildFormData(obj[property], formData, property);
        } else {
          formData.append(formKey, obj[property]);
        }

      }
    }
    return { formData, headers };
  }
}
