import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DAYS_OF_WEEK } from 'angular-calendar';

@Component({
  selector: 'callendar-bookings-callendar-header',
  templateUrl: './callendar-bookings-callendar-header.component.html',
  styles: []
})
export class CallendarBookingsCallendarHeaderComponent{

  @Input() view: string;
  @Input() viewDate: Date;
  @Input() locale: string = 'pl';
  @Output() viewChange: EventEmitter<string> = new EventEmitter();
  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();

  public startDate = DAYS_OF_WEEK.MONDAY;

  constructor() { }

}
