import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReviewsService } from './../../api/reviews.service';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'review-filters',
  templateUrl: './review-filters.component.html',
  styleUrls: ['./review-filters.component.scss']
})
export class ReviewFiltersComponent implements OnInit {
  @Input() q;
  
  @Output() query = new EventEmitter();
  
  modelChanged: Subject<string> = new Subject<string>();

  constructor(private reviewsService: ReviewsService) { }

  ngOnInit() {
    this.modelChanged
      .debounceTime(600)
      .distinctUntilChanged()
      .subscribe(res => {
        this.query.emit(this.q);
      });
  }
  
  updateModel(property) {
    this.modelChanged.next(property);
  }

}
