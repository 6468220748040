import { Component, OnInit, Input, ViewChild, AfterContentChecked, Output, EventEmitter } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CallendarBooking, BookingsService } from './../../api/bookings.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as Moment from 'moment';
import { parseDateToPickerFormat, parseDateToStringFormat } from './../callendar-bookings.helpers';
import { User } from './../../api/users.service';
import { UserSearchComponent } from 'app/utilities/user-search/user-search.component';
import {  } from 'events';

@Component({
  selector: 'callendar-bookings-modal',
  templateUrl: './callendar-bookings-modal.component.html',
  styleUrls: ['./callendar-bookings-modal.component.scss']
})
export class CallendarBookingsModalComponent implements OnInit, AfterContentChecked {

  @Input()
  event: CalendarEvent;

  @Input()
  service: any;

  @ViewChild('userSearch')
  userSearchBar: UserSearchComponent;

  @Output()
  eventAdded: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;
  public isShowNewClientForm: boolean = false;
  public formError: boolean = false;
  public userPlusLeft: number;

  constructor(
    private activeModal: NgbActiveModal,
    private bookingService: BookingsService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    const date = parseDateToPickerFormat(this.event.start);

    this.form = this.fb.group({
      date: [date, Validators.required],
      time_from: ['', Validators.required],
      time_to: ['', Validators.required],
      variant: ['', Validators.required],
      client: this.fb.group({
        id: [''],
        first_name: [''],
        last_name: [''],
        email: [''],
        phone: [''],
        description: [''],
      })
    });
  }

  ngAfterContentChecked() {
    this.form.get('time_from').patchValue(Moment(this.event.start).format('HH:mm'));
  }

  close() {
    this.activeModal.close();
  }

  setUser(user: User) {
    Object.keys(this.form.get('client').value).forEach(key => {
      this.form.get('client').get(key).patchValue(user[key]);
    });
    this.toggleShowNewClientForm();
    this.userPlusLeft = user.plus_left;
  }

  toggleShowNewClientForm() {
    this.isShowNewClientForm = true;
  }

  submit() {
    if (this.form.valid) {
      this.formError = false;
      const data: CallendarBooking = {
        date: parseDateToStringFormat(this.form.value.date),
        email: this.form.value.client.email,
        first_name: this.form.value.client.first_name,
        last_name: this.form.value.client.last_name,
        notes: this.form.value.client.description,
        phone: this.form.value.client.phone,
        service_variant_id: this.form.value.variant.id,
        time_from: this.form.value.time_from,
        time_to: this.form.value.time_to,
        user_id: this.form.value.client.id
      }
      this.bookingService.addBooking(this.service.id, data).subscribe(
        res => {
          this.eventAdded.emit();
          this.close();
        },
        err => console.log(err)
      )
    } else {
      this.formError = true;
    }
  }

  onChangeVariant(event: any) {
    const variant = this.form.get('variant').value;
    this.form.get('time_to').patchValue(Moment(this.event.start).add(variant.interval, 'minutes').format('HH:mm'))
  }

  enableNewClientForm() {
    if (this.userId) {
      this.form.get('client').reset();
      this.userSearchBar.inputElement.nativeElement.value = "";
    }
    if (!this.isShowNewClientForm) {
      this.toggleShowNewClientForm();
    }
    this.form.value();
  }

  get userId() {
    return this.form.get('client').get('id').value ? true : false;
  }
}
