import { Injectable } from '@angular/core';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../environments/environment";

@Injectable()
export class ProfilesService {

  constructor(private _tokenService: Angular2TokenService) { }

  getPagedProfiles(page, query, sort) {
    if (query) {
      query['category_eq'] = 'facility';
    } else {
      query = {"category_eq" : "facility"}
    }
    let q = "";
    let s = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      } 
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiEndpoint}/services?page=${page}${q}`;
    return this._tokenService.get(url)
            .map( response => response.json() )
     }

  editService(service) {
    let url = `${environment.apiEndpoint}/services/${service.id}`;
    return this._tokenService.put(url, service)
            .map( response => response.json() );
  }
  deleteService(serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}`;
    return this._tokenService.delete(url)
            .map( response => response.json() );
  }
}
