import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-check-modal',
  templateUrl: './check-modal.component.html',
  styleUrls: ['./check-modal.component.scss']
})

export class CheckModalComponent implements OnInit {
  @Output()
  decision = new EventEmitter();

  constructor(private modalInstace: NgbActiveModal) { }

  ngOnInit() {
  }

  close() {
    this.modalInstace.close();
  }

  continue() {
    this.decision.emit(true);
    this.close();
  }

}
