import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {
 
  constructor() {}

  ngOnInit() {
   }
}

