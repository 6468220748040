import { Injectable } from '@angular/core';
import { RequestOptions, RequestMethod } from '@angular/http';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../environments/environment";

export interface User {
  id: number
  first_name: string;
  last_name: string;
  email: string;
  description: string;
  phone: string;
  picture: string;
  status: string;
  type: string;
  created_at: Date;
  updated_at: Date;
  plus_left: number;
}

@Injectable()
export class UsersService {

  constructor(private _tokenService: Angular2TokenService) {
  }

  users = [];


  getUsers(page, query, sort){
    let q = "";
    let s = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiEndpoint}/users?page=${page}${q}`;
    return this._tokenService.get(url)
            .map( response => response.json() )
  }

  getUsersWithoutPaginations(query) {
    let q = "";
    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    const url = `${environment.apiEndpoint}/users?&except[]=limit&except[]=offset${q}`
    return this._tokenService.get(url)
      .map(response => response.json());
  }

  getUser(id){
    let url = `${environment.apiEndpoint}/users/${id}`;
    return this._tokenService.get(url)
            .map( response => response.json());
  }

  editUser(data, userId){
    let url = `${environment.apiBase}/${environment.apiEndpoint}/users/${userId}/`;

    let hdrs = this._tokenService.currentAuthHeaders;
    hdrs.append('enctype',"multipart/form-data")
    let requestOptions = new RequestOptions({
      method: RequestMethod.Put,
      headers: hdrs,
      url: url,
      body: data
    });

    return this._tokenService.request(requestOptions).map(
        (response) => response.json()
    );

    // return this._tokenService.put(url, data)
    //         .map( response => response.json() );
  }
  newUser(data){
    let url = `${environment.apiBase}/${environment.apiEndpoint}/users`;

    let hdrs = this._tokenService.currentAuthHeaders;
    hdrs.append('enctype',"multipart/form-data")
    let requestOptions = new RequestOptions({
      method: RequestMethod.Post,
      headers: hdrs,
      url: url,
      body: data
    });

    return this._tokenService.request(requestOptions).map(
        (response) => response.json()
    );
  }

  updateUserStatus(userId, status) {
    let url = `${environment.apiEndpoint}/users/${userId}`;
    return this._tokenService.put(url, {status: status})
            .map( response => response.json() );
  }


  deleteUser(userId){
    let url = `${environment.apiEndpoint}/users/${userId}`;
    return this._tokenService.delete(url)
            .map( response => response.json() );
  }

  uploadImage(userId, image) {
    let url = `${environment.apiEndpoint}/users/${userId}`;
    let data = JSON.stringify(image)
    return this._tokenService.put(url, {user: {avatar: data}})
            .map( response => response.json() );
  }

}
