import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import {
  CalendarEvent,
  DAYS_OF_WEEK,
  CalendarEventTitleFormatter
} from "angular-calendar";
import {
  floorToNearest,
  ceilToNearest,
  CustomEventTitleFormatter,
  currentViewDatesQuery
} from "./../callendar-bookings.helpers";
import { DayViewHourSegment } from "calendar-utils";
import { addDays, addMinutes, endOfWeek, format, isDate } from "date-fns";
import { fromEvent } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { BookingsService } from 'app/api/bookings.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CallendarBookingsModalComponent } from './../callendar-bookings-modal/callendar-bookings-modal.component';

@Component({
  selector: "callendar-bookings-callendar",
  templateUrl: "./callendar-bookings-callendar.component.html",
  styleUrls: ["./callendar-bookings-callendar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ]
})
export class CallendarBookingsCallendarComponent implements OnChanges {

  @Input('service')
  service: any;

  public viewDate: Date = new Date();
  public view: "week" | "month" | "day" = 'week';
  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  public weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  public events: CalendarEvent[] = [];
  public dragToCreateActive: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private bookingService: BookingsService,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.service.firstChange && changes.service.currentValue) {
      const service = changes.service.currentValue;
      this.getServiceBookings(service.id);
    }
  }

  public startDragToCreate(
    segment: DayViewHourSegment,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement
  ) {
    if (!this.service) {
      this.toastr.error('Najpierw wybierz usługę');
      return;
    }
    const newEvent: CalendarEvent = {
      id: 0,
      title: "",
      start: segment.date,
      meta: {
        tmpEvent: true
      },
      actions: [
        {
          label: 'edit ',
          onClick: (event) =>this.onNewEventEdit(event)
        },
        {
          label: ' delete',
          onClick:({event}: {event: CalendarEvent}): void => {
            this.events = this.events.filter(e => e !== event);
          }
        }
      ]
    };
    this.events = [...this.events, newEvent];
    const segmentPosition = segmentElement.getBoundingClientRect();
    this.dragToCreateActive = true;
    const endOfView = endOfWeek(this.viewDate);

    fromEvent(document, "click")
      .pipe(
        finalize(() => {
          delete newEvent.meta.tmpEvent;
          this.dragToCreateActive = false;
          this.onNewEventEdit({event: newEvent});
          this.refresh();
        }),
        takeUntil(fromEvent(document, "mouseup"))
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const minutesDiff = ceilToNearest(
          mouseMoveEvent.clientY - segmentPosition.top,
          30
        );

        const daysDiff = floorToNearest(
          mouseMoveEvent.clientX - segmentPosition.left,
          segmentPosition.width
        ) / segmentPosition.width;

        const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
        if (newEnd > segment.date && newEnd < endOfView) {
          newEvent.end = newEnd;
        }
        this.refresh();
      });
  }

  private refresh() {
    this.events = [...this.events];
    this.cdr.detectChanges();
  }

  private onNewEventEdit({ event }: { event: CalendarEvent }) {
    const modalRef = this.modalService.open(CallendarBookingsModalComponent)
    modalRef.componentInstance.event = event;
    modalRef.componentInstance.service = this.service;
    modalRef.componentInstance.eventAdded.subscribe(
      () => this.getServiceBookings(this.service.id)
    );
    modalRef.result
      .then(() => {this.removeNewEvent()})
      .catch(() => {this.removeNewEvent()})
  }

  private getServiceBookings(serviceId: number) {
    let times_q = currentViewDatesQuery(this.viewDate, this.view);
    let q = {
      ...times_q,
      status_not_eq: 'canceled',
    }
    this.bookingService.getServiceBookings(serviceId, q).subscribe(
      res => {
        this.events = [];
        this.drawEvents(res.data);
      },
      err => console.log(err)
    )
  }

  private drawEvents(events: any[]) {
    const calendarEvents: CalendarEvent[] = [];
    events.forEach(event => {
      calendarEvents.push({
        title: event.service.name,
        draggable: false,
        start: new Date(`${event.date} ${event.time_from}`),
        end: new Date(`${event.date} ${event.time_to}`),
        id: event.id,
        meta: {
          tmpEvent: false
        },
        resizable: {
          afterEnd: false,
          beforeStart: false,
        },
      })
    });
    this.events = [...this.events, ...calendarEvents];
    this.refresh();
  }

  public onViewDateChange(event) {
    this.viewDate = event;
    if (this.service.id) {
      this.getServiceBookings(this.service.id);
    }
  }

  public onViewChange(event) {
    this.view = event;
    if (this.service.id) {
      this.getServiceBookings(this.service.id);
    }
  }

  private removeNewEvent() {
    const index = this.events.findIndex((event, index, aray) => {
      return event.id === 0
    });
    this.events.splice(index,1);
    this.refresh();
  }
}
