import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { BookingsService, Booking } from "../../api/bookings.service";
import * as Moment from "moment";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss']
})
export class BookingFormComponent implements OnInit {

  bookingId: string;
  serviceId: string;
  booking: Booking;

  constructor(private stateService: StateService,
              private bookingsService: BookingsService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.serviceId = this.stateService.params.serviceId;
    this.bookingId = this.stateService.params.bookingId;

    if (this.serviceId && this.bookingId) {
      this.bookingsService.getBooking(this.serviceId, this.bookingId)
        .map( res => {
          res.date = {
            year: Moment(res.date).year(),
            month: Moment(res.date).month() + 1,
            day: Moment(res.date).date(),
          }
          return res
        })
        .subscribe(res => {
          console.log(res)
          this.booking = res
        })
    }
  }

  save(invalid, booking) {
    if (invalid) {
      this.toastr.error('Please fill all required data.','Error!');
      return
    }
    let bookingObj = Object.assign({}, booking);
    bookingObj.date = Moment({year: bookingObj.date.year, month: bookingObj.date.month - 1, day: bookingObj.date.day}).format("YYYY-MM-DD")
    if (this.bookingId) {
      this.bookingsService.updateBooking(this.serviceId, bookingObj)
        .subscribe( res => {
          console.log(res)
          this.toastr.success('Changes saved.', 'Success!');
          this.stateService.go('bookings.details', {serviceId: res.service.id, bookingId: res.id});
        })
    }
  }

  cancel() {
    if (this.bookingId) {
      this.stateService.go('bookings.details', {serviceId: this.serviceId, bookingId: this.bookingId});
    } else {
      this.stateService.go('bookings.list');
    }
  }

}
