import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import {} from 'googlemaps';

@Component({
  selector: 'address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class AddressSearchComponent implements OnInit {
  @Input()
  set location(location: string) {
    if (location) {
      this.searchElementRef.nativeElement.value = location;
    } else {
      this.searchElementRef.nativeElement.value = "";
    }
  }
  @Input()
  multiple:boolean;
  @Input()
  ui:string;
  @Input()
  editOnly:boolean = false;
  @Input()
  updateOnly:boolean = false;
  @Input()
  displayOnly:boolean = false;
  @Input()
  addresses:any = [];
  @Output()
  selectedAddresses = new EventEmitter();
  @ViewChild("search")
  public searchElementRef: ElementRef;
  error:boolean = false;
  editMode:boolean = false;
  updateMode:boolean = false;
  searchControl: FormControl;
  hasDownBeenPressed:boolean = false;
  validAddress:boolean = false;
  address_attributes: any = {};
  zoom: number = 6;
  latitude: number = 52.069316;
  longitude: number = 19.480290;
  markerLat: number;
  markerLng: number;
  addressesCopy = [];

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,) { }

  ngOnChanges(changes: {[propKey: string]: any}) {
    if (this.addresses.length > 0) {
      for (let i = 0; i < this.addresses.length; i++) {
        this.addresses[i].lat = Number(this.addresses[i].lat);
        this.addresses[i].lng = Number(this.addresses[i].lng);
      }
      this.latitude = this.addresses[0].lat;
      this.longitude = this.addresses[0].lng;
    }
  }
  ngOnInit() {
    if (this.addresses.length > 0) {
      for (let i = 0; i < this.addresses.length; i++) {
        this.addresses[i].lat = Number(this.addresses[i].lat);
        this.addresses[i].lng = Number(this.addresses[i].lng);
      }
      this.latitude = this.addresses[0].lat;
      this.longitude = this.addresses[0].lng;
      this.zoom = 8;
    }
    //create search FormControl
    this.searchControl = new FormControl();
    //set current position
    this.setCurrentPosition();
    //load Places Autocomplete
    this.searchElementRef.nativeElement.addEventListener('keydown', (e) => {
      if (e.keyCode === 40) {
        this.hasDownBeenPressed = true;
      }
    });
    this.searchElementRef.nativeElement.addEventListener('focus', () => { });
    this.searchElementRef.nativeElement.addEventListener('blur', () => {
      if (!this.validAddress) {
        this.searchElementRef.nativeElement.value = "";
      }
    });
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      google.maps.event.addDomListener(this.searchElementRef.nativeElement, 'keydown', (e) => {
        // Maps API e.stopPropagation();
        e.cancelBubble = true;
        // If enter key, or tab key
        if (e.keyCode === 13 || e.keyCode === 9) {
          // If user isn't navigating using arrows and this hasn't ran yet
          if (!this.hasDownBeenPressed && !e.hasRanOnce) {
            google.maps.event.trigger(e.target, 'keydown', {
              keyCode: 40,
              hasRanOnce: true,
            });
          }
        }
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();          
          if (typeof place.address_components !== 'undefined') {
            // reset hasDownBeenPressed in case they don't unfocus
            this.hasDownBeenPressed = false;
            this.validAddress = true;
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.markerLat = place.geometry.location.lat();
            this.markerLng = place.geometry.location.lng();
            this.zoom = 17;
            this.formatAdressAttributes(place);
            
            if (this.editOnly == false) {
              this.addLocation()
            } else if (this.editOnly == true) {
              this.saveLocation()
            }
          }
        })
      });
    });
  }
  
  markerDrag(event) {
    this.markerLat = event.coords.lat;
    this.markerLng = event.coords.lng;
  }
  
  setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 17;
      });
    }
  }
  
  addressFieldInputChange(e) {
    if (e.keyCode != 13 && e.keyCode != 9) {
      this.validAddress = false;
    }
    if (e.keyCode == 13 || e.keyCode == 9) {
      e.preventDefault();
    }
  }
  
  formatAdressAttributes(place) {
    let address_attributes = {}
    for (let i = 0; i < place.address_components.length; i++) {
      for (let j = 0; j < place.address_components[i].types.length; j++) {
        address_attributes[place.address_components[i].types[j]] = place.address_components[i].long_name;
      }
    }
    address_attributes["formatted_address"] = place.formatted_address;
    this.address_attributes = address_attributes;
  }
  
  setLocation() {
    if (this.searchElementRef.nativeElement.value == '') {
      this.error = true;
      return;
    }
    this.error = false;
    this.searchElementRef.nativeElement.value = '';
    this.validAddress = false;
    
    this.address_attributes["lat"] = this.markerLat;
    this.address_attributes["lng"] = this.markerLng;
    
    this.markerLat = null;
    this.markerLng = null;
    if (Object.keys(this.address_attributes).length > 0) {
      this.addresses.push(this.address_attributes)
    }    
    this.address_attributes = {};
  }
  addLocation() {
    this.setLocation()
    
    if (this.updateOnly) {
      this.updateMode = false;
    }
    
    this.selectedAddresses.emit(this.addresses);
  }
  addNewLocation() {
    this.updateMode = true;
  }
  cancelAddingNewLocation() {
    this.updateMode = false;
    this.resetForm();
  }
  editLocation(i) {
    this.copyAddresses(this.addresses)
    
    let address = this.addresses[0]
    
    this.addresses = []
    
    // set marker position
    this.markerLat = address.lat;
    this.markerLng = address.lng;
    
    
    this.editMode = true;
    this.searchElementRef.nativeElement.value = address.formatted_address;
    this.validAddress = true;
    this.address_attributes = address;
  }
  saveLocation() {
    this.setLocation()
    
    this.editMode = false;
    
    this.addresses[0].id = this.addressesCopy[0].id
    
    this.selectedAddresses.emit(this.addresses);
  }
  cancelEditingLocation() {
    this.addresses = this.restoreAddressesFromCopy()
    this.editMode = false;
  }
  cancel() {
    if (this.updateOnly == true) {
      this.cancelAddingNewLocation()
    } else if (this.editOnly == true) {
      this.cancelEditingLocation()
    }
  }
  copyAddresses(addesses) {
    this.addressesCopy = JSON.parse(JSON.stringify(addesses))
  }
  restoreAddressesFromCopy() {
    return JSON.parse(JSON.stringify(this.addressesCopy))
  }
  removeLocation(i) {
    if (confirm("Czy jesteś pewień?")) {
      if (this.updateOnly) {
        // set delete flag
        this.addresses[i]._destroy = "1";
      } else {
        // remove from list
        this.addresses.splice(i, 1)
      }
      this.selectedAddresses.emit(this.addresses);
    }
  }
  resetForm() {
    this.searchElementRef.nativeElement.value = '';
    this.validAddress = false;
  }
}
