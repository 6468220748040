import { UIRouterModule } from "@uirouter/angular";
import { DashboardComponent } from "./dashboard.component";

const routesConfig = {
  states: [ 
    { name: 'dashboard', url: '/dashboard', component:  DashboardComponent, data: {requiresAuth: true}}
  ]
}
 
export const routerModule = UIRouterModule.forChild(routesConfig)
