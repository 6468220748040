import { Component, OnInit } from '@angular/core';
import { BookingsService, Booking } from "../../api/bookings.service";
import { StateService } from "@uirouter/angular";

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit {

  serviceId: string;
  bookingId: string;
  booking: Booking;

  constructor(private bookingsService: BookingsService,
              private stateService: StateService) { }

  ngOnInit() {
    console.log(this.stateService)
    this.serviceId = this.stateService.params.serviceId;
    this.bookingId = this.stateService.params.bookingId;

    this.bookingsService.getBooking(this.serviceId, this.bookingId)
      .subscribe(res => {
        console.log(res);
        this.booking = res;
      })
  }

  cancelBooking(booking){
    if (confirm('Are you sure you want to cancel this booking?')) {
      this.bookingsService.cancelBooking(this.serviceId, this.bookingId)
        .subscribe(res => {
          console.log(res);
          booking.status = 'canceled';
        })
    }
  }

}
