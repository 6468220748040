import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ServiceVariants } from './../../api/services.service';

@Component({
  selector: 'service-variants',
  templateUrl: './service-variants.component.html',
  styleUrls: ['./service-variants.component.scss']
})
export class ServiceVariantsComponent {


  @Input()
  variants: ServiceVariants[];

  @Input()
  serviceId: number;

  @Output()
  success: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  onSuccess(event: any) {
    this.success.emit(event);
  }

}
