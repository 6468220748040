import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angular2TokenService } from 'angular2-token';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';

import { UsersModule } from "./users/users.module";
import { ProfilesModule } from './profiles/profiles.module';
import { ServicesModule } from "./services/services.module";
import { UtilitiesModule } from "./utilities/utilities.module";
import { AuthModule } from "./auth/auth.module";
import { DisciplinesModule } from "./disciplines/disciplines.module";
import { SettingsModule } from "./settings/settings.module";
import { CitiesModule } from "./cities/cities.module";
import { BookingsModule } from "./bookings/bookings.module";
import { BlogModule } from './blog/blog.module';
import { AgregationsModule } from './agregations/agregations.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ReviewsModule } from './reviews/reviews.module';
import { CompaniesModule } from './companies/companies.module';
import { CallendarBookingsModule } from './callendar-bookings/callendar-bookings.module';

import { routerModule } from './app.routing';
import { AppConfig } from "./app.config";

import { NavBarComponent } from './nav-bar/nav-bar.component';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent
  ],
  imports: [
    NgbModule,
    routerModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    UsersModule,
    ProfilesModule,
    ServicesModule,
    AuthModule,
    DisciplinesModule,
    CitiesModule,
    BookingsModule,
    SettingsModule,
    BlogModule,
    AgregationsModule,
    DashboardModule,
    ReviewsModule,
    CompaniesModule,
    CallendarBookingsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA2x5GHK_l69yz3MbT_24ZvD8ouBdU5w9c',
      libraries: ["places"]
    }),
  ],
  providers: [
    AppConfig,
    Angular2TokenService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
