import { UIRouterModule } from "@uirouter/angular";
import { DisciplinesComponent } from "./disciplines.component";

const routesConfig = {
  states: [ 
    { name: 'disciplines', url: '/disciplines', component:  DisciplinesComponent, data: {requiresAuth: true}}
  ]
}
 
export const routerModule = UIRouterModule.forChild(routesConfig)
