import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from '@uirouter/angular';

@Component({
  selector: 'service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent implements OnInit {

  @Input()
  service;

  @Input()
  displayOnly;

  @Output()
  verifyService = new EventEmitter();

  @Output()
  rejectService = new EventEmitter();

  @Output()
  showServicePhone = new EventEmitter();

  @Output()
  hideServicePhone = new EventEmitter();

  @Output()
  onChangePartnerProgram: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onChangeSmsNotifications: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private stateService: StateService) { }

  ngOnInit() {
  }

  showPrimatyService() {
    if (this.service.primary_service) {
      this.stateService.go('services.details', {id: this.service.primary_service.id})
    }
  }

  togglePartnerProgram() {
    this.onChangePartnerProgram.emit(!this.service.is_service_plus);
  }

  toggleSmsNotifications() {
    this.onChangeSmsNotifications.emit(!this.service.enabled_sms);
  }
}
