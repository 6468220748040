import { CalendarEvent, CalendarEventTitleFormatter, DAYS_OF_WEEK } from "angular-calendar";
import { endOfWeek, startOfWeek, startOfMonth, endOfMonth, format } from "date-fns";
import * as Moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

export function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  weekTooltip(event: CalendarEvent, title: string) {
    if (!event.meta.tmpEvent) {
      return super.weekTooltip(event, title);
    }
  }

  dayTooltip(event: CalendarEvent, title: string) {
    if (!event.meta.tmpEvent) {
      return super.dayTooltip(event, title);
    }
  }
}

export function currentViewDatesQuery(
  viewDate: Date,
  view: "month" | "week" | "day"
) {

  let q = {
    date_gteq: null,
    date_lteq: null
  };

  switch (view) {
    case "month":
      q.date_gteq = format(startOfMonth(viewDate), 'YYYY-MM-DD');
      q.date_lteq = format(endOfMonth(viewDate), 'YYYY-MM-DD');
      break;
    case "week":
      q.date_gteq = format(startOfWeek(viewDate, {weekStartsOn: DAYS_OF_WEEK.MONDAY}), 'YYYY-MM-DD');
      q.date_lteq = format(endOfWeek(viewDate, {weekStartsOn: DAYS_OF_WEEK.MONDAY}), 'YYYY-MM-DD');
      break;
    case "day":
      q.date_gteq = viewDate;
      q.date_lteq = viewDate;
      break;
  }

  return q;
}

export function parseDateToPickerFormat(date: Date): NgbDateStruct {
  return {
    year: parseInt(Moment(date).format('YYYY')),
    month: parseInt(Moment(date).format('MM')),
    day: parseInt(Moment(date).format('DD')),
  }
}

export function parseDateToStringFormat(date:NgbDateStruct ): string {
  return `${date.year}-${date.month}-${date.day}`;
}