import { Component, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Observable, of, Subject, merge } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'app/api/users.service';

@Component({
  selector: 'user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent {

  @ViewChild('searchInput')
  public searchInput: NgbTypeahead;

  @ViewChild('input')
  public inputElement: any;

  @Output()
  public onFind:EventEmitter<any> = new EventEmitter<any>();

  public model: string | any;
  public searching: boolean = false;
  public searchFailed: boolean = false;
  public focus$:Subject<string> = new Subject<string>();
  public click$:Subject<string> = new Subject<string>();

  constructor(private usersService: UsersService) { }

  search = (text$: Observable<string>) => {
    if (this.model == '') {
      return of([]);
    }
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.searchInput.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(query => {
        if (query.length) {
          const q = {
            first_name_or_last_name_or_email_cont: query,
            companies_plus_enabled_eq: true,
          }
          return this.usersService.getUsersWithoutPaginations(q).pipe(
            map(res => res.data),
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }))
        } else {
          return of([])
        }
      }),
      tap(() => this.searching = false)
    )
  }

  formatter = (x: {first_name: string, last_name: string; email: string}) => {
    if (x.first_name && x.last_name) {
      return `${x.first_name} ${x.last_name}`;
    }
    return `${x.email}`;
  };

  onSearchInputChange() {
    if (this.model.id) {
      this.onFind.emit(this.model)
    }
  }

}
