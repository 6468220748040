import { Injectable } from '@angular/core';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../environments/environment";

@Injectable()
export class DashboardService {

  constructor(private _tokenService: Angular2TokenService) { }
  
  get() {
    let url = `${environment.apiEndpoint}/dashboard`;
    return this._tokenService.get(url)
            .map( response => response.json() )
  }

}
