import { Injectable } from '@angular/core';
import { RequestOptions, RequestMethod } from '@angular/http';
import { Angular2TokenService } from 'angular2-token';
import { environment } from "../../environments/environment";
import { Observable, Subject } from 'rxjs';

export interface ServiceVariants {
  id: number
  service_id: number
  description: string
  interval: number
  name: string
  price: number
  created_at: Date;
  updated_at: Date;
  is_service_variant_plus? : boolean;
  plus_price?: number;
  max_participants?: number;
  plus_admin_only?: boolean;
}

@Injectable()
export class ServicesService {

  constructor(private _tokenService: Angular2TokenService) { }

  getPagedServices(page, query, sort) {
    console.log(query);
    if (query) {
      query['category_not_eq'] = 'facility';
    } else {
      query = {"category_not_eq" : "facility"}
    }
    let q = "";
    let s = "";

    if (query) {
      for (let key in query) {
        if (query[key]) {
          q += `&q[${key}]=${query[key]}`
        }
      }
    }
    if (sort) {
      s = '&q[s]=' + sort;
    }
    q = q + s;
    let url = `${environment.apiEndpoint}/services?page=${page}${q}`;
    return this._tokenService.get(url)
            .map( response => response.json() )
    }

  findService(query) {
    let q = "";
    if (query) {
      for (let key in query) {
        q += `&q[${key}]=${query[key]}`
      }
    }
    let url = `${environment.apiEndpoint}/services?${q}`;
    return this._tokenService.get(url)
            .map( response => response.json()['data']);
  }

  deleteService(serviceId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}`;
    return this._tokenService.delete(url)
            .map( response => response.json() );
  }

  getService(id) {
    let url = `${environment.apiEndpoint}/services/${id}`;
    return this._tokenService.get(url)
            .map( response => response.json() );
  }

  removeServiceImage(serviceId, imageId) {
    let url = `${environment.apiEndpoint}/services/${serviceId}/pictures/${imageId}`;
    return this._tokenService.delete(url)
            .map( response => response.json() )
  }

  setServiceImageDefault(image, service) {
    let url = `${environment.apiEndpoint}/services/${service.id}/pictures/${image.id}/set_default.json`;
    let data = {
      image: image
    }
    return this._tokenService.put(url, data)
            .map( response => response.json() )
  }

  addServiceImage(data, serviceId) {
    let url = `${environment.apiBase}/${environment.apiEndpoint}/services/${serviceId}/pictures`;

    let hdrs = this._tokenService.currentAuthHeaders;
    hdrs.append('enctype',"multipart/form-data")
    let requestOptions = new RequestOptions({
      method: RequestMethod.Post,
      headers: hdrs,
      url: url,
      body: data
    });

    return this._tokenService.request(requestOptions).map(
        (response) => response.json()
    );
  }

  newService(service) {
    let url = `${environment.apiEndpoint}/services/`;
    return this._tokenService.post(url, service)
            .map( response => response.json() );
  }

  editService(service) {
    let url = `${environment.apiEndpoint}/services/${service.id}`;
    return this._tokenService.put(url, service)
            .map( response => response.json() );
  }

  verifyService(id) {
    let url = `${environment.apiEndpoint}/services/${id}/verify`;
    return this._tokenService.post(url, {})
            .map( response => response.json() );
  }
  rejectService(id) {
    let url = `${environment.apiEndpoint}/services/${id}/reject`;
    return this._tokenService.post(url, {})
            .map( response => response.json() );
  }

  verificationComments(id, data) {
    let url = `${environment.apiEndpoint}/services/${id}`;
    return this._tokenService.put(url, data)
      .map( response => response.json() );
  }

  hideServicePhone(id) {
    const url = `${environment.apiEndpoint}/services/${id}`;
    return this._tokenService.put(url, {id: id, phone_visible: false})
      .map(response => response.json());
  }

  showServicePhone(id) {
    const url = `${environment.apiEndpoint}/services/${id}`;
    return this._tokenService.put(url, {id: id, phone_visible: true})
      .map(response => response.json());
  }

  createUser(id, data) {
    let url = `${environment.apiEndpoint}/agregations/${id}/assign`;
    return this._tokenService.post(url, data)
      .map( response => response.json() );
  }
}
