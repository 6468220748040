import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'app/api/company.service';
import { UIRouter } from '@uirouter/core';
import { Company, Employee } from '../companies.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEmployeesModalComponent } from './../add-employees-modal/add-employees-modal.component';

@Component({
  selector: 'companies-details',
  templateUrl: './companies-details.component.html',
  styleUrls: ['./companies-details.component.scss']
})
export class CompaniesDetailsComponent implements OnInit {

  public company: Company;
  public employess: Employee[];

  public page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  };

  constructor(
    private route: UIRouter,
    private companyService: CompanyService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.getCompany();
    this.getCompanyEmployees(this.page.size + 1);
  }

  private getCompany() {
    this.companyService.getCompany(this.companyId).subscribe(
      res => {
        this.company = res;
      },
      err => console.log(err)
    )
  }

  getCompanyEmployees(page) {
    this.companyService.getCompanyEployees(page, this.companyId).subscribe(
      res => {
        this.page.totalElements = res.size;
        this.page.size = res.per_page;
        this.employess = res.data;
      },
      err => console.log(err)
    )
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
  }

  public openAddEmployeesModal() {
    const modalRef = this.modalService.open(AddEmployeesModalComponent);
    modalRef.componentInstance.companyId = this.companyId;
  }

  get companyId() {
    return this.route.globals.params.id;
  }
}
