import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
// import { Discipline } from "../../api/disciplines.service";
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ServiceChangesCheckService } from '../../services/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../services/service-changes-validation/service-changes-validation.service';

@Component({
  selector: 'disciplines-form',
  templateUrl: './disciplines-form.component.html',
  styleUrls: ['./disciplines-form.component.scss']
})
export class DisciplinesFormComponent implements OnInit, OnDestroy {

  @Input()
  discipline;

  @Output()
  saved = new EventEmitter();

  @Output()
  canceled = new EventEmitter();

  @Output()
  selected = new EventEmitter();

  transitionHook: any;

  constructor(private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService) {
              this.transitionHook = transitionService.onBefore({from: 'disciplines'}, (trans: Transition)=>{
                  return changesValidationService.validate(this.discipline)
                })
              }

  ngOnInit() {
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  save(invalid, disc){
    if (invalid) {
      return
    }

    this.saved.emit(disc)
  }

  updateDiscipline(disc) {
    this.selected.emit(disc)
  }

  cancel() {
    this.canceled.emit()
  }

}
