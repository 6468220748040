import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'companies',
  template: `
  <div class="container-fluid">
    <div class="row">
      <div class="col py-2">
        <ui-view></ui-view>
      </div>
    </div>
  </div>
  `,
  styles: []
})
export class CompaniesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
