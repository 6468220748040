import { Component, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { ServiceVariants, ServicesService } from 'app/api/services.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'service-variant',
  templateUrl: './service-variant.component.html',
  styleUrls: ['./service-variant.component.scss']
})
export class ServiceVariantComponent implements AfterContentInit{

  public model = {
    plus_price: '',
    max_participants: '',
    plus_admin_only: false,
  }

  @Input()
  variant: ServiceVariants;

  @Input()
  serviceId: number;

  @Output()
  success: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private servicesService: ServicesService,
    private toastr: ToastrService,
  ) { }

  ngAfterContentInit() {
    this.model.max_participants = this.variant.max_participants as any;
    this.model.plus_price = this.variant.plus_price as any;
    this.model.plus_admin_only = this.variant.plus_admin_only;
  }

  submit(id: number, is_service_variant_plus: boolean, {plus_price, max_participants, plus_admin_only }) {
    const data = {
      service_variants_attributes: { id, is_service_variant_plus, plus_price, max_participants, plus_admin_only },
      id: this.serviceId
    }
    this.servicesService.editService(data).subscribe(
      res => {
        this.toastr.success('variant edited', 'Success');
        this.success.emit(true);
      },
      err => this.toastr.error('Unexpected error', 'Error')
    )
  }

}
