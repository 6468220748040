import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routerModule } from './comapnies.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyService } from './../api/company.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { CompaniesComponent } from './companies.component';
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { CompaniesDetailsComponent } from './companies-details/companies-details.component';
import { CompaniesFormComponent } from './companies-form/companies-form.component';
import { AddEmployeesModalComponent } from './add-employees-modal/add-employees-modal.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
  ],
  declarations: [
    CompaniesComponent,
    CompaniesListComponent,
    CompaniesDetailsComponent,
    CompaniesFormComponent,
    AddEmployeesModalComponent,
  ],
  providers: [
    CompanyService
  ],
  entryComponents: [
    AddEmployeesModalComponent
  ]
})
export class CompaniesModule { }
