import { Component, OnInit } from '@angular/core';
import { Angular2TokenService } from 'angular2-token';
import { StateService } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent implements OnInit {

  data = {};
  sessionExpired: boolean = false;


  constructor(private _tokenService: Angular2TokenService,
              private stateService:StateService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.sessionExpired = this.stateService.params['sessionExpired'];
    if (this._tokenService.userSignedIn()) {
      this.stateService.go('users')
    }
  }

  login(formInvalid, data) {
    this._tokenService.signIn(data).subscribe(
      res => {
        this.stateService.go('dashboard');
      },
      err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      });
  }

}
