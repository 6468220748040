import { Component, OnInit, Input, Output } from '@angular/core';
import * as Moment from "moment";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface Day {
  id: number,
  name: string,
  available: boolean,
  hours: [
    {
      from: string,
      to: string,
      available?: boolean
    }
  ]
}

@Component({
  selector: 'app-service-hours-modal',
  templateUrl: './service-hours-modal.component.html',
  styleUrls: ['./service-hours-modal.component.scss']
})

export class ServiceHoursModalComponent implements OnInit {

  @Input()
  interval: number;

  @Input()
  day: Array<Day>;

  times;

  constructor(private modalInstace: NgbActiveModal) { }

  ngOnInit() {
    console.log(this.interval)
    this.createHours(this.day)
  }

  createHours(day) {
    this.times = [];
    let startDay = Moment().startOf('day');
    console.log(Moment().startOf('day'))
    console.log(Moment('11:30', 'HH:mm'))

    let t = 24 * 60 / this.interval;
    while (t--) {
      let hourObject = {
        from: startDay.format('HH:mm'),
        to: "",
        available: false
      }
      for (let i = 0; i < day.open.length; i++) {
        if (startDay.isBetween(Moment(day.open[i].from, 'HH:mm'), Moment(day.open[i].to, 'HH:mm'), null, '[)')) {
          hourObject.available = true;
        }
      }
      startDay.add(this.interval, 'minute');
      hourObject.to = startDay.format('HH:mm')
      this.times.push(hourObject)
    }
    console.log(this.times)
  }

  save(day, times) {
    day.intervals = 0;
    day.open = [];
    let startTime = '';
    let endTime = '';
    for (let i = 0; i < times.length; i++) {
      if (times[i].available) {
        day.intervals ++
        if (!startTime) {
          startTime = times[i].from;
        }
        endTime = times[i].to;
      } else if (startTime && endTime) {
        day.open.push({from: startTime, to: endTime});
        startTime = '';
        endTime = '';
      }
    }

    if (day.intervals === 0) {
      day.available = false;
    }

    this.close();
  }

  close() {
    this.modalInstace.close();
  }

}
