import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Http, Response, RequestOptions, Headers, RequestMethod } from '@angular/http';
import { Angular2TokenService } from 'angular2-token';
import * as Moment from "moment";
import { environment } from 'environments/environment';

@Injectable()
export class AgregationsService {

  constructor(private _tokenService: Angular2TokenService) { }
  
  getData(website: string): Observable<any> {
    let url = `${environment.apiEndpoint}/agregations/scrape`;
    let data = { url: website };
    return this._tokenService.post(url, data)
      .map(respnse => respnse.json());
  }
  
  createProfile(service, disciplines, weekdays, files, markerLat, markerLng) {
    let selectedFiles = [];
    for (let file of files) {
      if (file.selected) {
        selectedFiles.push(file)
      }
    }
    files = selectedFiles;

    let formData:FormData = new FormData();

    if (service.addresses_attributes.length > 0) {
      service.location = service.addresses_attributes[0].formatted_address;
      service.addresses_attributes = JSON.stringify(service.addresses_attributes);
    }

    if (service.addresses_attributes.length == 0) {
      delete service.addresses_attributes;
    }
    
    for (let key in service) {
      formData.append(key, service[key])
    }
    
    if (!service.addresses_attributes) {
      service.addresses_attributes = []
    }

    if (service.addresses_attributes.length > 0) {
      service.addresses_attributes = JSON.parse(service.addresses_attributes);
    }

    let hoursArray = []
    let startMoment = Moment(service.openTime, "HHmm");
    let endMoment = Moment(service.closeTime, "HHmm");

    let t = endMoment.diff(startMoment, 'm') / 15;

    while (t--) {
      hoursArray.push(startMoment.format('HHmm'))
      startMoment.add(15, 'minute');
    }

    let availability_attributes = {
      days: 0,
      interval: service.interval,
      hours: {
        from: service.openTime,
        to: service.closeTime,
        price: service.price,
        location: service.addresses_attributes[0]
      }
    };

    for (let i = 0; i < weekdays.length; i++) {
      console.log(weekdays[i])
      if (weekdays[i].selected) {
        availability_attributes.days += weekdays[i].value;
        availability_attributes[weekdays[i].engName] = hoursArray;
      }
    }
    
    let discipline_ids = [];

    for (let i = 0; i < disciplines.length; i++) {
      if (disciplines[i].selected) {
        discipline_ids.push(disciplines[i].id)
      }
    }

    formData.append('availability_attributes', JSON.stringify(availability_attributes));
    if (service.addresses_attributes.length > 0) {
      formData.append('lat', service.addresses_attributes[0].lat);
      formData.append('lng', service.addresses_attributes[0].lng);
    }
    formData.append('discipline_ids', JSON.stringify(discipline_ids));
    formData.append('image', files[0].url);
    
    let pictures_attributes = []
    if (files.length > 1) {
      files.splice(0, 1)
    }
    for (let file of files) {
      pictures_attributes.push({source: file.url})
    }
    
    formData.append('pictures_attributes', JSON.stringify(pictures_attributes));

    let url = `${environment.apiBase}/${environment.apiEndpoint}/agregations/import`;

    let hdrs = this._tokenService.currentAuthHeaders;
    hdrs.append('enctype',"multipart/form-data")
    let requestOptions = new RequestOptions({
      method: RequestMethod.Post,
      headers: hdrs,
      url: url,
      body: formData
    });

    return this._tokenService.request(requestOptions).map(
        (response) => response.json()
    );
  }

}
