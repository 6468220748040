import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'cities-form',
  templateUrl: './cities-form.component.html',
  styleUrls: ['./cities-form.component.scss']
})
export class CitiesFormComponent implements OnInit {

  @Input()
  city;

  @Output()
  saved = new EventEmitter();

  @Output()
  canceled = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  save(invalid, city){
    if (invalid) {
      return
    }

    this.saved.emit(city)
  }

  cancel() {
    this.canceled.emit()
  }

}
