import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StateService } from '@uirouter/core';
import { BlogService } from './../../api/blog.service';


@Component({
  selector: 'blog-preview',
  templateUrl: './blog-preview.component.html',
  styleUrls: ['./blog-preview.component.scss']
})
export class BlogPreviewComponent implements OnInit {

  postId:number;
  html: string;

  get sanitizedHtml(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.html);
  }

  constructor(private blogService: BlogService,
              private stateService: StateService,
              private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.postId = this.stateService.params.postId;
    if (this.postId) {
      this.blogService.getPost(this.postId).subscribe(
        res=> {
          this.html = res.content;
        },
        err => console.log(err)
      )
    }
  }
}
