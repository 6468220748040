import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../api/services.service';
import { UIRouter } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RejectServiceModalComponent } from './../../utilities/reject-service-modal/reject-service-modal.component';

@Component({
  selector: 'profiles-details',
  templateUrl: './profiles-details.component.html',
  styleUrls: ['./profiles-details.component.scss']
})
export class ProfilesDetailsComponent implements OnInit {

  service;
  location;
  file: File;
  assign: any = {
    email: "",
    user_kind: ""
  }
  assingSubmitted: boolean = false;

  constructor(private servicesService:ServicesService,
              private route:UIRouter,
              private modalService:NgbModal,
              private toastr: ToastrService) { }

  ngOnInit() {
    let id = this.route.globals.params.id;
    this.servicesService.getService(id)
    .subscribe(service => {
      this.service = service;

      let locationObject = {
        name: service.location,
        lat: service.lat,
        lng: service.lng,
        serviceId: service.id
      };

      this.location = locationObject;
    }, err => {
      console.error(err);
      this.toastr.error(err.statusText, err.status);
    })
  }

  addUser() {
    this.assingSubmitted = true;

    if (!this.assign.email || !this.assign.user_kind) { return }

    let id = this.route.globals.params.id;

    let confirmed = confirm(`Czy chcesz utworzyć użytkownika z adresem email ${this.assign.email} i przypisać do niego ten profil?`)
    if (confirmed) {
      this.servicesService.createUser(id, {user: this.assign})
      .subscribe(
        res => {
          this.assign = { email: "", user_kind: "" }
          this.assingSubmitted = false;
          alert("Użytkownik został utworzony i połączony z profilem")
          this.ngOnInit();
        },
        err => {
          let error = JSON.parse(err["_body"])
          let errors = Object.keys(error)
          let errorKey = errors[0]
          if (errorKey) {
            alert(`${errorKey} ${error[errorKey]}`)
          }
        }
      )
    }
  }

  removeImage(image) {
    if (confirm("Are you sure?")) {
      this.servicesService.removeServiceImage(this.service.id, image.id)
        .subscribe( response => {
          console.log( "removed", response);
          this.toastr.success('Image removed.', 'Success!');
          this.service.pictures.splice(this.service.pictures.indexOf(image), 1)
        }, err => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
        })
    }
  }

  setAsDefault(image) {
    this.toastr.info('Sending data.', null);
    this.servicesService.setServiceImageDefault(image, this.service)
      .subscribe( response => {
        this.toastr.success('Image set to default.', 'Success!');
        this.service = response;
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
  }

  imageAdded(event) {

    this.toastr.info('Uploading image.', null);
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      this.file = fileList[0];

      let formData:FormData = new FormData();
      formData.append('source', this.file, this.file.name);
      this.servicesService.addServiceImage(formData, this.service.id)
      .subscribe( res => {
        this.toastr.success('Image added.', 'Success!');
        this.service.pictures.push(res);
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
    }
  }

  verifyService(id){
    if (this.service.service_type == 'service') {
      if (this.service.disciplines.length == 0) {
        alert("ADD A VALID DISCIPLINE TO THIS SERVICE FIRST")
        return
      }
    }
    this.servicesService.verifyService(id)
      .subscribe( (service) => {
        this.service = service;
        this.toastr.success('Service is now verified.', 'Success!');
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
  }

  rejectService(id) {
    let modalRef = this.modalService.open(RejectServiceModalComponent);
    modalRef.componentInstance.reason.subscribe(
      res => {
        let data = {
          comments_attributes: [res]
        }
        this.servicesService.verificationComments(id,data)
          .subscribe(
            res => {
              console.log(res);
              this.servicesService.rejectService(id)
                .subscribe( (service) => {
                  this.service = service;
                  this.toastr.success('Service is now rejected.', 'Success!');
                  console.log('service rejected success', service);
                }, err => {
                  console.error(err);
                  this.toastr.error(err.statusText, err.status);
                })
            },
            err => console.log(err)
          )
      }
    );
  }

  showServicePhone(id) {
    this.servicesService.showServicePhone(id)
      .subscribe(
        res => {
          this.service.phone_visible = true;
          this.toastr.success('Service now shows phone number.', 'Success!');
        },
        err => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
        }
      )
  }

  hideServicePhone(id) {
    this.servicesService.hideServicePhone(id)
      .subscribe(
        res => {
          this.service.phone_visible = false;
          this.toastr.success('Service now hides phone number.', 'Success!');
        },
        err => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
        }
      )
  }

  public onChangePartnerProgram(is_service_plus) {
    let id = this.route.globals.params.id;
    const data = { is_service_plus, id };
    this.servicesService.editService(data).subscribe(
      res => {
        this.ngOnInit();
      }
    )
  }
}

