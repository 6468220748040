import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServicesService } from '../../api/services.service';
import { UIRouter, StateService, TransitionService, Transition, HookResult } from "@uirouter/angular";
import { ToastrService } from 'ngx-toastr';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { ServiceChangesCheckService } from '../../services/service-changes-check/service-changes-check.service';
import { ServiceChangesValidationService } from '../../services/service-changes-validation/service-changes-validation.service';
import { DisciplinesService } from "../../api/disciplines.service";

@Component({
  selector: 'service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss']
})
export class ServiceFormComponent implements OnInit, OnDestroy {

  service = {
    id: null,
    description: "",
    name: "",
    user: "",
    disciplines: [],
    phone: ''
  };
  transitionHook: any;
  disciplines: any;
  constructor(private disciplinesService: DisciplinesService,
              private servicesService:ServicesService,
              private route:UIRouter,
              private stateService:StateService,
              private toastr: ToastrService,
              config: NgbTooltipConfig,
              private checkChangesService: ServiceChangesCheckService,
              private changesValidationService: ServiceChangesValidationService,
              private transitionService: TransitionService) {
                config.placement = 'bottom';
                config.triggers = 'hover';
                config.container = 'body';
                this.transitionHook = transitionService.onBefore({from: 'services.**'}, (trans: Transition)=>{
                    return changesValidationService.validate(this.service)
                })
              }

  ngOnInit() {
    if (this.route.globals.params.id) {
      let id = this.route.globals.params.id;
      this.servicesService.getService(id)
      .subscribe(service => {
        console.log(service)
        let serviceObject = {
          id: service.id,
          name: service.name,
          phone: service.phone,
          description: service.description,
          service_type: service.service_type,
          disciplines: service.disciplines,
          suggested_discipline: service.suggested_discipline,
          user: service.hosts[0].id
        }
        this.service = serviceObject;
        this.checkChangesService.assignObject(this.service);
        this.disciplinesService.getDisciplines()
          .subscribe( res => {
            this.disciplines = res;
            for (let discipline of this.service.disciplines) {
              for (let globalDiscipline of this.disciplines) {
                if (globalDiscipline.id == discipline.id) {
                  globalDiscipline["selected"] = true;
                }
              }
            }
          })
      }, err => {
        console.error(err);
        this.toastr.error(err.statusText, err.status);
      })
    } else {
      this.checkChangesService.assignObject(this.service);
    }
    this.changesValidationService.resetResolved();
  }

  ngOnDestroy() {
    this.transitionHook();
  }

  save(formInvalid, service) {
    this.changesValidationService.resolved = true;

    if (formInvalid) {
        return
    }

    let discipline_ids = []
    for (let i = 0; i < this.disciplines.length; i++) {
      if (this.disciplines[i]['selected']) {
        discipline_ids.push(this.disciplines[i].id)
      }
    }
    service['discipline_ids'] = JSON.stringify(discipline_ids);

    if (this.route.globals.params.id) {
      console.log(formInvalid, service)
      this.servicesService.editService(service)
        .subscribe( (service) => {
          this.toastr.success('Service info edited.', 'Success!');
        }, err => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
        })
    } else {
      this.servicesService.newService(service)
        .subscribe( (service) => {
          this.toastr.success('Service have been created.', 'Success!');
          this.stateService.go('services.details', {id: service.id})
        }, err => {
          console.error(err);
          this.toastr.error(err.statusText, err.status);
        })
    }

  }

  cancel() {
    if (this.route.globals.params.id) {
      if (this.service["service_type"] == 'service') {
        this.stateService.go('services.details', {id: this.route.globals.params.id})
      } else {
        this.stateService.go('profiles.details', {id: this.route.globals.params.id})
      }
    } else {
      this.stateService.go('services.list')
    }
  }

}
