import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { routerModule } from './blog.routing';

import { QuillModule } from 'ngx-quill';
import { BlogService } from './../api/blog.service';

import { BlogComponent } from './blog.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogFormComponent } from './blog-form/blog-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlogPreviewComponent } from './blog-preview/blog-preview.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routerModule,
    QuillModule,
    NgbModule.forRoot()
  ],
  declarations: [BlogComponent, BlogListComponent, BlogFormComponent, BlogPreviewComponent],
  providers:[BlogService]
})
export class BlogModule { }
