export const QuillEditorConfig = {
  placeholder: "insert content...",
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': ['#FF6000', '#333333', '#999999'] }, { 'background': ['#FF6000', '#333333', '#FFFFFF'] }],
      ['link', 'image'],

      ['clean']
    ]
  }
};