import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as Moment from 'moment';
import { NgbActiveModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;


@Component({
  selector: 'app-service-closed-modal',
  templateUrl: './service-closed-modal.component.html',
  styleUrls: ['./service-closed-modal.component.scss']
})
export class ServiceClosedModalComponent implements OnInit {

  interval = 60;
  times: Array<object>;

  @Input()
  date;

  @Output()
  newDate = new EventEmitter();

  hoveredDate: NgbDateStruct;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  allBlocked: boolean;

  constructor(private modalInstace: NgbActiveModal,
              private calendar: NgbCalendar) {}

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

  ngOnInit() {
    console.log(this.date)
    Moment()
    if (!this.date.new) {
      this.fromDate = {
        year: Moment(this.date.from).year(),
        month: Moment(this.date.from).month() + 1,
        day: Moment(this.date.from).date(),
      };
      this.toDate = {
        year: Moment(this.date.to).year(),
        month: Moment(this.date.to).month() + 1,
        day: Moment(this.date.to).date(),
      };
      if (this.date.hours[0].from == "00:00" && this.date.hours[0].to == "24:00") {
        this.allBlocked = true;
      }
    } else {
      this.fromDate = {
        year: Moment().year(),
        month: Moment().month() + 1,
        day: Moment().date(),
      };
      this.toDate = {
        year: Moment().year(),
        month: Moment().month() + 1,
        day: Moment().date(),
      };
      this.allBlocked = true;
    }


    this.createHours(this.date);
  }

  save(date, times) {

    date.hours = [];

    let startTime = '';
    let endTime = '';
    for (let i = 0; i < times.length; i++) {
      if (times[i].blocked) {
        if (!startTime) {
          startTime = times[i].from;
        } else if (i == times.length - 1) {
          console.log(startTime);
          console.log(endTime);
          endTime = times[i].to;
          date.hours.push({from: startTime, to: endTime});
        }
        endTime = times[i].to;
      } else if (startTime && endTime) {
        date.hours.push({from: startTime, to: endTime});
        startTime = '';
        endTime = '';
      }
    }

    if (this.allBlocked || date.hours.length < 1) {
      date.hours = [{
        from: "00:00",
        to: "24:00"
      }];
    }

    if (!this.toDate) {
      this.toDate = this.fromDate;
    }

    this.fromDate.month --;
    this.toDate.month --;

    date.from = Moment(this.fromDate);
    date.to = Moment(this.toDate);

    this.newDate.emit(date);
    this.close();
  }

  createHours(date) {
    this.times = [];
    let startDay = Moment().startOf('day');

    let t = 24 * 60 / this.interval;
    while (t--) {
      let hourObject = {
        from: startDay.format('HH:mm'),
        to: "",
        blocked: false
      }
      if (!date.new) {
        for (let i = 0; i < date.hours.length; i++) {
          if (startDay.isBetween(Moment(date.hours[i].from, 'HH:mm'), Moment(date.hours[i].to, 'HH:mm'), null, '[)')) {
            hourObject.blocked = true;
          }
        }
      }
      startDay.add(this.interval, 'minute');
      hourObject.to = startDay.format('HH:mm')
      this.times.push(hourObject)
    }
    if (date.new) {
      this.allBlocked = true;
      console.log(this.allBlocked)
    }
    console.log(this.times)
  }

  changeBlocked(times, current) {
    if (this.allBlocked) {
      for (let i = 0; i < times.length; i++) {
          times[i].blocked = true;
      }
      this.allBlocked = false;
    }
    current.blocked = !current.blocked;
  }

  allDayChanged(times) {
    if (this.allBlocked) {
      for (let i = 0; i < times.length; i++) {
          times[i].blocked = true;
      }
    } else {
      for (let i = 0; i < times.length; i++) {
          times[i].blocked = false;
      }
    }
  }

  close() {
    this.modalInstace.close();
  }

}
