import { Component, OnInit } from '@angular/core';
import { BookingsService, Booking } from "../../api/bookings.service";
import { StateService } from "@uirouter/angular";

@Component({
  selector: 'app-bookings-list',
  templateUrl: './bookings-list.component.html',
  styleUrls: ['./bookings-list.component.scss']
})

export class BookingsListComponent implements OnInit {

  bookings: Booking;
  pagination;
  viewTable = false;
  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
  }
  loading = false;
  q: any = {
    date_eq: ''
  };
  sort: any;
  dateCopy: any;
  date: any;

  constructor(private bookingsService: BookingsService,
    private stateService: StateService) {
    this.page.pageNumber = 0;
  }

  ngOnInit() {
    this.setPage({
      offset: 0
    });
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.bookingsService.getBookings(this.page.pageNumber + 1, this.q, this.sort).subscribe(pagedData => {
      this.page.totalElements = pagedData.size;
      this.page.size = pagedData.per_page;
      this.bookings = pagedData.data;
      this.viewTable = true;
    });
  }

  onSort(event) {
    this.loading = true;
    let s = event.sorts[0];
    let prop = s.prop.replace(/\./g, "_");
    this.sort = prop + " " + s.dir;
    this.bookingsService.getBookings(0, this.q, this.sort)
      .subscribe(res => {
        this.bookings = res.data;
        this.loading = false;
      });
    }
}
