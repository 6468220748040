import { Component, OnInit } from '@angular/core';
import { DashboardService } from "../api/dashboard.service";

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  tiles: any = [
    {
      key: "profiles",
      notification_key: "profiles_notification",
      name: "Profile",
      value: 0,
      notification_value: 0
    },
    {
      key: "services",
      notification_key: "services_notification",
      name: "Usługi",
      value: 0,
      notification_value: 0
    },
    {
      key: "users",
      notification_key: "users_notification",
      name: "Użytkownicy",
      value: 0,
      notification_value: 0
    },
    {
      key: "cities",
      notification_key: "cities_notification",
      name: "Miasta",
      value: 0,
      notification_value: 0
    },
    {
      key: "disciplines",
      notification_key: "disciplines_notification",
      name: "Dyscypliny",
      value: 0,
      notification_value: 0
    },
    {
      key: "reviews",
      notification_key: "reviews_notification",
      name: "Oceny",
      value: 0,
      notification_value: 0
    },
    {
      key: "booking_leads",
      notification_key: "booking_leads_notification",
      name: "Leady",
      value: 0,
      notification_value: 0
    },
    {
      key: "bookings",
      notification_key: "bookings_notification",
      name: "Rezerwacje",
      value: 0,
      notification_value: 0
    },
    {
      key: "newsletter_subscriptions",
      notification_key: "newsletter_subscriptions_notification",
      name: "Newslettery",
      value: 0,
      notification_value: 0
    },
  ]
  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    this.dashboardService.get()
      .subscribe(
        response => {
          for (let item in response) {
            for (let tile of this.tiles) {
              if (tile["key"] == item) {
                tile["value"] = response[item]
              }
              if (tile["notification_key"] == item) {
                tile["notification_value"] = response[item]
              }
            }
          }
        }
      )
  }

}
