import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { routerModule } from './profiles.routing';
import { ServicesModule } from '../services/services.module';
import { ProfilesService } from './../api/profiles.service';
import { UtilitiesModule } from "../utilities/utilities.module";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProfilesComponent } from './profiles.component';
import { ProfilesListComponent } from './profiles-list/profiles-list.component';
import { ProfilesDetailsComponent } from './profiles-details/profiles-details.component';
import { ProfilesFilterComponent } from './profiles-filter/profiles-filter.component';

@NgModule({
  imports: [
    CommonModule,
    routerModule,
    NgxDatatableModule,
    ServicesModule,
    UtilitiesModule,
    FormsModule,
  ],
  declarations: [ProfilesComponent, ProfilesListComponent, ProfilesDetailsComponent, ProfilesFilterComponent],
  providers: [ProfilesService]
})
export class ProfilesModule { }
