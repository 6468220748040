import { Component, OnInit } from '@angular/core';
import { StateService } from "@uirouter/angular";
import { QuillEditorConfig } from './editor.config';
import { BlogService, IBlog } from './../../api/blog.service';
import { ServicesService } from './../../api/services.service';
import { environment } from './../../../environments/environment';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operator/map';
import { switchMap } from 'rxjs/operator/switchMap';
import { debounceTime } from 'rxjs/operator/debounceTime';

@Component({
  selector: 'blog-form',
  templateUrl: './blog-form.component.html',
  styleUrls: ['./blog-form.component.scss']
})
export class BlogFormComponent implements OnInit {
  blog: IBlog = {
    name: undefined,
    title: undefined,
    image: undefined,
    short: undefined,
    content: undefined,
    blog_postable_id: undefined,
  }
  editorOptions = QuillEditorConfig;
  showAllImages: boolean = false;

  private service;
  private formError:boolean;
  private formSuccess:boolean;

  search = (text$: Observable<string>): Observable<string> =>
    text$.
      debounceTime(300).
      distinctUntilChanged().
      switchMap(term => this.servicesService.findService({name_start:term, category_eq: 'facility', verification_status_eq: 'verified'}));

  formatter = (x: {name: string}):string => `${x.name}`;

  constructor(private blogService: BlogService,
              private servicesService: ServicesService,
              private stateService: StateService) { }

  ngOnInit() {
    this.blog['id'] = this.stateService.params.postId;
    if (this.blog['id']) {
      this.blogService.getPost(this.blog['id']).subscribe(
        res=> {
          this.service = res.blog_postable;
          this.blog = res;
        },
        err => console.log(err)
      )
    }
  }

  onFieldChange(): void {
    this.formError = false;
    this.formSuccess = false;
  }

  savePost(publish: boolean): void {
    if (this.blog.id) {
      this.updatePost(publish);
      return
    }
    if (this.service) {
      this.blog.blog_postable_id = this.service.id;
      this.blog.blog_postable_type = 'Service';
    }
    if (this.validateForm()) {
      this.blogService.save(this.blog).subscribe(
        res => {
          if (publish) {
            this.publishAfterSave(res.id, true)
          } else {
            this.formSuccess = true;
          }
        },
        err => console.log(err)
      );
    }
    else {
      this.formError = true;
    }
  }

  selectImage(url: string): void {
    this.blog.image = url;
  }

  toggleAllImages() {
    this.showAllImages = true;
  }

  private publishAfterSave(id: number, publish: boolean) {
    this.blogService.publishPost(id, publish).subscribe(
      res => {
        this.formSuccess = true;
      },
      err => console.log(err)
    )
  }

  private validateForm():boolean {
    let fields = ['name', 'title', 'image', 'short', 'content'];
    for(let i of fields) {
      if (!this.blog[i] || this.blog[i] == '') {
        return false
      }
    }
    return true;
  }

  private updatePost(publish: boolean): void {
    if (this.service) {
      this.blog.blog_postable_id = this.service.id;
      this.blog.blog_postable_type = 'Service';
    }
    if (this.validateForm()) {
      this.blogService.updatePost(this.blog.id, this.blog).subscribe(
        res => {
          if (publish) {
            this.publishAfterSave(res.id, true)
          } else {
            this.formSuccess = true;
          }
        },
        err => console.log(err)
      );
    }
    else {
      this.formError = true;
    }
  }
}
