import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { Angular2TokenService } from 'angular2-token';

export interface City {
  id?: number,
  name: string
}

@Injectable()
export class CitiesService {

  constructor(private _tokenService: Angular2TokenService) { }

  getCities() {
    let url = `${environment.apiEndpoint}/cities`;
    return this._tokenService.get(url)
            .map( response => response.json() )
  }

  saveCity(city) {
    let url = `${environment.apiEndpoint}/cities/${city.id}`;
    return this._tokenService.put(url, city)
            .map( response => response.json() )
  }

  newCity(city) {
    let url = `${environment.apiEndpoint}/cities`;
    return this._tokenService.post(url, city)
            .map( response => response.json() )
  }

}
